import React from 'react';
import Flags from 'country-flag-icons/react/1x1';

export default function LanguageToggle({ language, setLanguage }) {
  const Flag = Flags[language];

  function toggleLanguage() {
    if (language !== 'US') {
      setLanguage('US');
    } else {
      setLanguage('ES');
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        width: '70px',
        height: '21px',
        fontWeight: 'bold'
      }}
      onClick={() => toggleLanguage()}
      onKeyUp={() => toggleLanguage()}
      role="button"
      tabIndex={0}
    >
      <span style={{ marginRight: '0.5rem' }}>{language}</span>
      <span> | </span>
      <Flag style={{ height: '19px', width: '31px', marginLeft: '0.5rem' }} />
    </div>
  );
}
