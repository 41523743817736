import React, { useEffect, useRef, useState } from 'react';
import { Howl } from 'howler';

import audioVisual from '../../assets/audioVisual.png';

export default function VolumeCheckMedia(props) {
  const [isMediaPlaying, setMediaPlaying] = useState(false);
  const playButtonRef = useRef();

  // instantiating a new player
  const sound = new Howl({
    src: [props.mediaUrl],
    html5: true,
    // onstop: () => console.log('the music has stopped playing ')
  });

  function playSound() {
    setMediaPlaying(true);
    sound.volume(0.10);
    sound.play();
  }

  // eslint-disable-next-line no-unused-vars
  function stopSound() {
    setMediaPlaying(false);
    sound.stop();
  }

  useEffect(() => {
    // triggering the audio once the component starts
    setTimeout(() => {
      playButtonRef.current.click();
    }, 1000);

    //stopping the sound when the component unmounts
    return () => stopSound();
  }, []);

  return (
    <div>
      <button
        aria-label="music_button"
        ref={playButtonRef}
        onClick={playSound}
        onKeyUp={playSound}
        style={{ display: 'none' }}
      />
      <img
        style={{ marginBottom: '3rem' }}
        src={audioVisual}
        alt="playing audio"
      />
    </div>
  );
}
