import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport
} from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTheme } from '../../context/ThemeProvider';
import './TestsTaken.css';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto'
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider'
          }
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

function CustomToolbar(props) {
  return (
    <GridToolbarContainer
      style={{
        textTransform: 'none',
        width: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '50%'
        }}
      >
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
          sx={{
            width: {
              xs: 1,
              sm: 'auto'
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            '& .MuiSvgIcon-root': {
              mr: 0.5
            },
            '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider'
            }
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '50%'
        }}
      >
        <GridToolbarExport
          style={{
            color: '#000000',
            textDecoration: 'underline',
            textUnderlineOffset: '3px',
            textTransform: 'none',
            paddingRight: '2rem'
          }}
          startIcon={<FileDownloadIcon style={{ marginTop: '5px' }} />}
        />
        <GridToolbarColumnsButton
          style={{
            color: '#000000',
            textDecoration: 'underline',
            textUnderlineOffset: '3px',
            textTransform: 'none'
          }}
          startIcon={<FilterListIcon />}
        />
      </div>
    </GridToolbarContainer>
  );
}

export default function TestsTaken(props) {
  const theme = useTheme();
  const data = {
    columns: [
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 130,
        align: 'center'
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        width: 130,
        align: 'center'
      },
      {
        field: 'cefrLevel',
        headerName: 'CEFR Level',
        width: 180,
        align: 'center'
      },
      {
        field: 'cefrDescription',
        headerName: 'CEFR Description',
        width: 130,
        align: 'center'
      },
      {
        field: 'overallScore',
        headerName: 'Overall Score',
        width: 130,
        align: 'center'
      },
      {
        field: 'assessmentDate',
        headerName: 'Assessment Date',
        width: 170,
        align: 'center'
      },
      {
        field: 'userEmail',
        headerName: 'User Email',
        width: 230,
        align: 'center'
      },
    ],
    rows: [
      {
        id: 0,
        firstName: 'Sofia',
        lastName: 'Calderes',
        cefrLevel: 'B2',
        department: 'Finance',
        cefrDescription: 'Upper Intermediate',
        overallScore: '79%',
        assessmentDate: 'January 23, 2022'
      },
      {
        id: 1,
        firstName: 'Daniel',
        lastName: 'Jase',
        cefrLevel: 'B2',
        department: 'Marketing',
        cefrDescription: 'Upper Intermediate',
        overallScore: '79%',
        assessmentDate: 'January 21, 2022'
      },
      {
        id: 2,
        firstName: 'Rachel',
        lastName: 'Martine',
        cefrLevel: 'A2',
        department: 'Human Resources',
        cefrDescription: 'Elementary',
        overallScore: '58%',
        assessmentDate: 'February 1, 2022'
      },
      {
        id: 3,
        firstName: 'Anna',
        lastName: 'Oceon',
        cefrLevel: 'B1',
        department: 'Finance',
        cefrDescription: 'Intermediate',
        overallScore: '65%',
        assessmentDate: 'February 14, 2022'
      },
      {
        id: 4,
        firstName: 'Geraldine',
        lastName: 'Velasquez',
        cefrLevel: 'B2',
        department: 'Operations',
        cefrDescription: 'Upper Intermediate',
        overallScore: '72%',
        assessmentDate: 'January 23, 2022'
      }
    ]
  };

  useEffect(() => {
    if (props.employees) {
      setRows(props.employees);
    }
  }, [props.employees]);

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState([]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <div
      style={{
        marginTop: '80px',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '1100px'
      }}
    >
      <div
        style={{
          height: 350,
          width: '100%',
          borderRadius: '15px'
        }}
      >
        <h2
          style={{
            fontFamily: theme.typography.h1.fontFamily,
            fontWeight: 800,
            fontSize: theme.typography.h2.fontSize,
            display: 'flex',
            justifyContent: 'flex-start',
            paddingBottom: '5rem'
          }}
        >
          Tests Taken
        </h2>
        <DataGrid
          localeText={{
            toolbarColumns: 'Filter Categories'
          }}
          components={{ Toolbar: CustomToolbar }}
          rows={rows || []}
          columns={data.columns}
          style={{
            fontFamily: theme.typography.h1.fontFamily,
            backgroundColor: theme.primaryColors.white,
            borderRadius: '15px'
          }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch('')
            }
          }}
        />
      </div>
    </div>
  );
}
