import React, { useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import { useTheme } from '../../context/ThemeProvider';
import './CompanyCode.css';

export default function CompanyCode({ companyCode }) {
  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  async function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      console.log('Browser not compatible');
    } else {
      await navigator.clipboard.writeText(text);
    }
  }

  const handleCopyClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    copyTextToClipboard(companyCode)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.warn(err.message);
      });
  };
  return (
    <div className="CompanyCode">
      <h2>Your Company Code</h2>
      <div
        className="codeContainer"
        style={{
          background: theme.colors.main,
          color: theme.colors.white
        }}
      >
        <button
          className="codeShareButton"
          style={{ color: theme.colors.white }}
          onClick={handleCopyClick}
        >
          {companyCode}
          {isCopied ? (
            <span className="copiedSuccess">
              <CheckCircleOutlineIcon
                sx={{ paddingLeft: '10px', marginRight: '5px' }}
                fontSize="medium"
              />
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <Box
                  sx={{
                    borderRadius: '5px',
                    p: 1,
                    bgcolor: 'background.paper',
                    fontSize: '14px',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}
                >
                  Copied!
                </Box>
              </Popper>
            </span>
          ) : (
            <FileCopyIcon
              sx={{ paddingLeft: '10px', alignSelf: 'flex-start' }}
              fontSize="medium"
            />
          )}
        </button>
        <p className="codeExplanation">
          Your affiliated users can enter your “Company Code” before taking the
          BELA assessment to share their test results with you. Copy your code
          and share it with your users.
        </p>
      </div>
    </div>
  );
}
