import React from 'react';
import './MediumButton.css';

export default function MediumButton({ text, onClick, disabled }) {
  return (
    <button className="mediumButton" disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
}
