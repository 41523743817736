import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../PageTitle/PageTitle';
import LevelComponent from '../../LevelComponent/LevelComponent';
import GetCertificate from '../../GetCertificate/GetCertificate';
import FiveLatestScores from '../../FiveLatestScores/FiveLatestScores';
import CEFRLevels from '../../CEFRLevels/CEFRLevels';
import './AssessmentScores.css';

export default function AssessmentScores() {
  return (
    <div
      className="AssessmentScoresPage"
      style={{ marginTop: '1.5rem', paddingBottom: '6rem' }}
    >
      <Helmet>
        <title>BELA - Assessment Scores</title>
      </Helmet>
      <div className="assessmentScoresContents">
        <div className="assessmentLeft">
          <PageTitle text="BELA Assessment Scores" />
          <LevelComponent />
        </div>
        <div className="dataTableRow">
          <FiveLatestScores />
        </div>
        <GetCertificate />
      </div>
      <CEFRLevels />
    </div>
  );
}
