import React from 'react';
import Modal from '@mui/material/Modal';
import { Container, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import SmallButton from '../SmallButton/SmallButton';

import warningIcon from '../../assets/warning-svgrepo-com.png';

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 583,
  height: 313,
  bgcolor: '#FFFFFF',
  border: '2px solid #707070',
  borderRadius: '24px',
  p: 4,
  color: '#000000'
};

export default function ModalComponent({ open, handleClose, page }) {
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/home');
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container sx={style}>
          <IconButton sx={{ alignSelf: 'flex-end' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '1rem'
            }}
          >
            {page === 'volumeCheck' && (
              <img alt="warning-icon" src={warningIcon} />
            )}
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginLeft: '1.5rem',
                whiteSpace: 'pre-wrap'
              }}
            >
              {page === 'volumeCheck'
                ? 'You need sound to take\n the assessment'
                : 'What is a “Company Code”?'}
            </span>
          </div>
          <span
            style={{
              marginBottom: '3rem',
              textAlign: 'center',
              whiteSpace: 'pre-wrap'
            }}
          >
            {page === 'volumeCheck'
              ? 'Try increasing your volume or try reconfiguring your audio settings. You need your audio to correctly answer the questions in the BELA test.'
              : `A “Company Code” is a unique set of characters assigned to an
            organization. Users affiliated with an organization can input this
            code to securely share their assessment results with their
            organization. Ask your organization’s point of contact (e.g.
            HR manager) to receive this unique code.`}
          </span>
          {page === 'volumeCheck' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'flex-end',
                alignItems: 'center'
              }}
            >
              <button
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  marginRight: '2rem',
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  border: 'none'
                }}
                onClick={goHome}
                tabIndex="0"
              >
                Take the test later
              </button>
              <SmallButton text="Try again" onClick={handleClose} />
            </div>
          )}
        </Container>
      </Modal>
    </div>
  );
}
