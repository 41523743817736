import React from 'react';
import './SmallButton.css';

export default function SmallButton({ text, onClick, style }) {
  return (
    <button style={style} className="smallButton" onClick={onClick}>
      {text}
    </button>
  );
}
