import React, { useState, useEffect } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton
} from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import { grid } from '@mui/system';
import { useTheme } from '../../context/ThemeProvider';
import './FiveLatestScores.css';
import { useLoggedInUser } from '../../context/UserProvider';

function CustomToolbar() {
  const theme = useTheme();
  return (
    <GridToolbarContainer
      className="fiveLatestGridHeader"
      style={{
        textTransform: 'none',
        width: '100%'
      }}
    >
      <h2
        style={{
          fontWeight: 800,
          fontSize: '24px',
          marginBottom: '20px',
          color: '#000000'
        }}
      >
        Your Five Latest BELA Scores
      </h2>
      <GridToolbarColumnsButton
        style={{
          color: '#000000',
          textDecoration: 'underline',
          textUnderlineOffset: '3px',
          textTransform: 'none',
          justifyContent: 'flex-end',
          alignItems: 'center',
          fontSize: '14px',
          fontFamily: 'Nunito',
          fontWeight: 700
        }}
        startIcon={<FilterListIcon />}
      />
    </GridToolbarContainer>
  );
}

export default function FiveLatestScores() {
  const { testsTaken } = useLoggedInUser();

  const formatValues = (params) => {
    if (params.value == null) {
      return '';
    }

    const valueFormatted = Number(params.value).toLocaleString();
    return `${valueFormatted} %`;
  };

  const columns = [
    {
      field: 'overallScore',
      headerName: 'Overall Score',
      width: 170,
      align: 'center',
      valueFormatter: (params) => formatValues(params)
    },
    {
      field: 'cefrLevel',
      headerName: 'CEFR Level',
      width: 130,
      align: 'center'
    },
    {
      field: 'cefrDescription',
      headerName: 'CEFR Description',
      width: 200,
      align: 'center'
    },
    {
      field: 'grammar',
      headerName: 'Grammar',
      width: 130,
      align: 'center',
      valueFormatter: (params) => formatValues(params)
    },
    {
      field: 'reading',
      headerName: 'Reading',
      width: 130,
      align: 'center',
      valueFormatter: (params) => formatValues(params)
    },
    {
      field: 'listening',
      headerName: 'Listening',
      width: 130,
      align: 'center',
      valueFormatter: (params) => formatValues(params)
    },
    {
      field: 'assessmentDate',
      headerName: 'Assessment Date',
      width: 170,
      align: 'center'
    }
  ];

  const rows1 = {
    id: 'No Id to display',
    overallScore: 'No Scores to display now',
    cefrLevel: 'no levels to display now',
    cefrDescription: 'no description available',
    grammar: 'no scores available',
    reading: 'no scores available',
    listening: 'no scores available',
    assessmentDate: 'no dates available'
  };

  const [last5Tests, setLast5Tests] = useState([]);

  const getLast5 = () => {
    const last5 = [];

    testsTaken &&
      testsTaken.forEach((test, index) => {
        if (index <= 4) {
          last5.push(test);
        }
      });
    setLast5Tests(last5);
  };

  useEffect(() => {
    getLast5();
  }, []);

  const rows2 =
    last5Tests &&
    last5Tests.map((test, index) => ({
      id: index,
      overallScore: test.overallScore.score,
      cefrLevel: test.overallScore.level,
      cefrDescription: test.overallScore.description,
      grammar: test.grammarScore.score,
      reading: test.readingScore.score,
      listening: test.listeningScore.score,
      assessmentDate: test.date
    }));

  return (
    <div
      className="FiveLatestScores"
      style={{
        marginTop: '120px',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 0,
        backgroundColor: 'white',
        borderRadius: '15px',
      }}
    >
      <div
        style={{
          height: 350,
          width: '100%',
          borderRadius: '15px'
        }}
      >
        <DataGrid
          className="fiveLatestGrid"
          localeText={{
            toolbarColumns: 'Filter Categories'
          }}
          components={{ Toolbar: CustomToolbar }}
          rows={testsTaken ? rows2 : rows1}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          sx={{ fontSize: '16px', border: 'none' }}
        />
      </div>
    </div>
  );
}
