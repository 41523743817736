import React from 'react';
import { FadeLoader } from 'react-spinners';
import Box from '@mui/material/Box';

import { useTheme } from '../../context/ThemeProvider';

export default function Loader() {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex' }}>
      <FadeLoader color={theme.colors.main} />
    </Box>
  );
}
