import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from '@mui/material/Container';
import { useTheme } from '../../../context/ThemeProvider';
import Navbar from '../../Navbar/Navbar';
import Home from '../Home/Home';
import Footer from '../../Footer/Footer';
import AssessmentScores from '../AssessmentScores/AssessmentScores';
import ProfileSettings from '../ProfileSettings';
import PaymentSuccessful from '../../PaymentSuccessful/PaymentSuccessful';

import './DashboardHome.css';
import { useLoggedInUser } from '../../../context/UserProvider';

export default function DashboardHome() {
  const { loggedInUser } = useLoggedInUser();

  useEffect(() => {
    console.log(loggedInUser);

    // accessing the first test taken in the array
    // getDocsFromCollection('test', tests.tests_taken[0]);
  }, []);

  return (
    <Container
      className="individualDashboardMainContainer"
      disableGutters
      maxWidth="false"
    >
      <Navbar />
      <div className="routeDiv">
        <Routes>
          <Route path="/assessment-scores" element={<AssessmentScores />} />
          <Route path="/profile-settings" element={<ProfileSettings />} />
          <Route path="/payment-successful" element={<PaymentSuccessful />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
      </div>
      {/* <Footer
        className="dashboardFooter"
        backgroundColor={theme.colors.dashboardBackground}
        foregroundColor={theme.colors.black}
      /> */}
    </Container>
  );
}
