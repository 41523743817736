import React, { useEffect, useState } from 'react';
// import OverallSvg from './Images/8.svg';
import GrammarSVG from '../../assets/GrammarSVG.png';
import ReadingSVG from '../../assets/ReadingSVG.png';
import ListeningSVG from '../../assets/ListeningSVG.png';
import './LevelComponent.css';
import { useTheme } from '../../context/ThemeProvider';
import { useLoggedInUser } from '../../context/UserProvider';

export default function LevelComponent() {
  const { bestScore } = useLoggedInUser();

  const theme = useTheme();

  return (
    <div
      className="levelComponent"
      style={{ backgroundColor: theme.primaryColors.white }}
    >
      <div className="column1LevelComponent">
        <div
          className=".column1TopTitleLevelComponent"
          style={{ fontWeight: theme.typography.h2.fontWeight }}
        >
          Overall Score
        </div>
        <div
          className="column1PercentageLevelComponent"
          style={{
            fontFamily: theme.typography.h1.fontFamily,
            fontWeight: theme.typography.h1.fontWeight
          }}
        >
          {bestScore ? `${bestScore.overallScore.score}%` : 'N/A'}
        </div>
        <div style={{ fontSize: '0.8rem' }}>
          {bestScore ? bestScore.date : 'No information available'}
        </div>
        {bestScore && (
          <div
            style={{
              backgroundColor: '#C1E7FF',
              borderRadius: '30px',
              paddingRight: '0.5rem',
              paddingLeft: '0.5rem',
              paddingTop: '0.1rem',
              paddingBottom: '0.1rem',
              fontWeight: 'bold',
              marginTop: '0.5rem'
            }}
          >
            <span style={{ color: '#000000' }}>Best Score</span>
          </div>
        )}
      </div>
      <div className="column2LevelComponent">
        <div
          className="column2TopTitleLevelComponent"
          style={{
            fontFamily: theme.typography.h3.fontFamily,
            fontSize: theme.typography.h2.fontSize,
            fontWeight: theme.typography.h2.fontWeight
          }}
        >
          {bestScore
            ? bestScore.overallScore.description
            : 'No Description Available'}
        </div>
        <div className="sectionsRowLevelComponent">
          <div
            className="sectionColumnLevelComponent"
            style={{
              backgroundColor: theme.primaryColors.dashboardBackground
            }}
          >
            <div className="centerComp">Grammar</div>
            <img
              className="levelCompIcon"
              src={GrammarSVG}
              alt="grammar icon"
            />
            <div className="centerComp" style={{ fontWeight: 'bold' }}>
              {bestScore ? `${bestScore.grammarScore.score}%` : 'N/A'}
            </div>
          </div>
          <div
            className="sectionColumnLevelComponent"
            style={{
              backgroundColor: theme.primaryColors.dashboardBackground
            }}
          >
            <div className="centerComp">Reading</div>
            <img
              className="levelCompIcon"
              src={ReadingSVG}
              alt="grammar icon"
            />
            <div className="centerComp" style={{ fontWeight: 'bold' }}>
              {bestScore ? `${bestScore.readingScore.score}%` : 'N/A'}
            </div>
          </div>
          <div
            className="sectionColumnLevelComponent"
            style={{
              backgroundColor: theme.primaryColors.dashboardBackground
            }}
          >
            <div className="centerComp">Listening</div>
            <img
              className="levelCompIcon"
              src={ListeningSVG}
              alt="grammar icon"
            />
            <div className="centerComp" style={{ fontWeight: 'bold' }}>
              {bestScore ? `${bestScore.readingScore.score}%` : 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
