import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';

import { useTheme } from '../../context/ThemeProvider';
import { useLoggedInUser } from '../../context/UserProvider';

// import '@coreui/coreui/dist/css/coreui.min.css';

export default function ScoreTable() {
  const [scores, setScores] = useState(null);

  const theme = useTheme();

  const { latestScore, bestScore } = useLoggedInUser();

  return (
    <div
      className="ScoreTable"
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '80px',
        textAlign: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '0',
          alignItems: 'baseline'
        }}
      >
        <h2
          style={{
            fontWeight: 800,
            fontSize: '24px',
            marginBottom: '20px',
            color: '#000000'
          }}
        >
          My BELA Scores
        </h2>
        <Link
          to="/home/assessment-scores#cefrLevel"
          style={{ color: '#000', fontWeight: 'bold' }}
        >
          What do my scores mean?
        </Link>
      </div>
      <CTable
        responsive
        borderless
        style={{
          maxHeight: '159px',
          backgroundColor: theme.colors.white,
          lineHeight: 3,
          borderRadius: '15px',
          padding: '1rem',
          width: '100%'
        }}
      >
        <CTableHead>
          <CTableRow className="border-bottom border-bottom-primary-light">
            <CTableHeaderCell
              scope="col"
              style={{
                padding: 0,
                borderBottom: '1px solid #d3d3d3',
                color: '#000000',
                fontWeight: '700'
              }}
            >
              Overall Score
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              style={{
                padding: 0,
                borderBottom: '1px solid #d3d3d3',
                color: '#000000',
                fontWeight: '700'
              }}
            >
              CEFR Level
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              style={{
                padding: 0,
                borderBottom: '1px solid #d3d3d3',
                color: '#000000',
                fontWeight: '700'
              }}
            >
              CEFR Description
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              style={{
                padding: 0,
                borderBottom: '1px solid #d3d3d3',
                color: '#000000',
                fontWeight: '700'
              }}
            >
              Assessment Date
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              style={{
                padding: 0,
                borderBottom: '1px solid #d3d3d3',
                color: '#000000',
                fontWeight: '700'
              }}
            >
              Status
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow>
            <CTableDataCell style={{ color: '#000000' }}>
              {bestScore
                ? `${bestScore.overallScore.score}%`
                : 'No scores to display'}
            </CTableDataCell>
            <CTableDataCell style={{ color: '#000000' }}>
              {bestScore ? bestScore.overallScore.level : ''}
            </CTableDataCell>
            <CTableDataCell style={{ color: '#000000' }}>
              {bestScore ? bestScore.overallScore.description : ''}
            </CTableDataCell>
            <CTableDataCell style={{ color: '#000000' }}>
              {bestScore ? bestScore.date : ''}
            </CTableDataCell>
            <CTableDataCell>
              <span
                style={{
                  fontWeight: 'bold',
                  border: 'none',
                  padding: '0.1rem 0.5rem',
                  borderRadius: '19px',
                  backgroundColor: bestScore ? '#C1E7FF' : '#FFF',
                  fontSize: '16px',
                  fontFamily: 'Nunito',
                  color: '#000000'
                }}
              >
                {bestScore ? 'Best Score' : ''}
              </span>
            </CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell style={{ color: '#000000' }}>
              {latestScore
                ? `${latestScore.overallScore.score}%`
                : 'No scores to display'}
            </CTableDataCell>
            <CTableDataCell style={{ color: '#000000' }}>
              {latestScore ? latestScore.overallScore.level : ''}
            </CTableDataCell>
            <CTableDataCell style={{ color: '#000000' }}>
              {latestScore ? latestScore.overallScore.description : ''}
            </CTableDataCell>
            <CTableDataCell style={{ color: '#000000' }}>
              {latestScore ? latestScore.date : ''}
            </CTableDataCell>
            <CTableDataCell style={{ color: '#000000' }}>
              {latestScore ? 'Latest Score' : ''}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </div>
  );
}
