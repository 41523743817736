import React from 'react';
import * as ImIcons from 'react-icons/im';
import * as BsIcons from 'react-icons/bs';
import * as IoIcons from 'react-icons/io';

const SidebarData = [
  {
    title: 'Dashboard',
    path: '/organization',
    icon: <ImIcons.ImHome />,
    cName: 'org-active-link'
  }
];

export default SidebarData;
