const theme = {
  colors: {
    main: '#104D64',
    dashboardBackground: '#F7F8FC',
    black: '#000000',
    button: '#F16E1A',
    white: '#FFFFFF',
    signInFooter: '#033448',
    signInElements: '#326477',
    dashboardBanners: '#E0EBF0',
    activityLinkBackground: '#EDF3F5',
    bestScoreBackground: '#FED139',
    disabledText: '#6C6C6C',
    greyIcons: '#B1B1B1',
    inactiveInputBorder: '#CFCFCF',
    activeInput: '#2B87F2',
    error: '#E20000',
    confirmation: '#55AA00',
    line: '#36CFF8',
    circle1: '#183CE8',
    circle2: '#070C5F',
    circle3: '#CA25E9',
    circle4: '#6523CE',
    circle5: '#EFB035',
    scoreButton: '#FFD139'
  },
  typography: {
    h1: {
      fontFamily: 'Nunito',
      fontWeight: '900',
      fontSize: '32px'
    },
    h2: {
      fontFamily: 'Nunito',
      fontWeight: '700',
      fontSize: '24px'
    },
    h3: {
      fontFamily: 'Nunito',
      fontSize: '18px'
    },
    h4: {
      fontFamily: 'Nunito',
      fontSize: '16px'
    },
    h5: {
      fontFamily: 'Nunito',
      fontSize: '14px'
    }
  },
  boxShadows: {
    secondary: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    main: 'rgba(149, 157, 165, 0.15) 0px 8px 24px;'
  },
  overlay: {
    backgroundColar: 'rgba(0,0,0, 0.78)'
  },
  fontWeight: {
    extraBold: '800',
    bold: '700'
  },
  primaryColors: {
    main: '#104D64',
    dashboardBackground: '#F7F8FC',
    black: '#000000',
    button: '#FDB12A',
    white: '#FFFFFF'
  },
  shades: {
    signInFooter: '#033448',
    signInElements: '#326477',
    dashboardBanners: '#E0EBF0',
    activityLinkBackground: '#EDF3F5',
    bestScoreBackground: '#FED139',
    diabledText: '#6C6C6C',
    greyIcons: '#B1B1B1',
    inactiveInputBorder: '#CFCFCF'
  },
  systemColors: {
    activeInput: '#2B87F2',
    confirmation: '#55AA00',
    error: '#E20000'
  },
  signInPageElements: {
    line: '#36CFF8',
    circle1: '#183CE8',
    circle2: '#070C5F',
    circle3: '#CA25E9',
    circle4: '#6523CE',
    circle5: '#EFB035'
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    width: 513,
    height: 471,
    backgroundColor: '#FFFFFF',
    borderRadius: '30px',
    padding: '1.5rem',
    shadow: 'rgba(149, 157, 165, 0.15);'
  },
  secondaryButton: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '16px',
    fontWeight: 700,
    borderBottom: '1px solid #000000',
    padding: 0,
    cursor: 'pointer',
    marginLeft: '0.2rem'
  },
  shadows: {
    main: '0px 8px 24px rgba(149, 157, 165, 0.15)',
    secondary: '0px 2px 8px 0px rgba(99, 99, 99, 0.2)'
  }
};

export default theme;
