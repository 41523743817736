import React from 'react';
import * as ImIcons from 'react-icons/im';
import * as BsIcons from 'react-icons/bs';
import * as IoIcons from 'react-icons/io';

const SidebarData = [
  {
    title: 'Home',
    path: '/home',
    icon: <ImIcons.ImHome />,
    cName: 'navbar-active-link'
  },
  {
    title: 'Assessment Scores',
    path: '/home/assessment-scores',
    icon: <BsIcons.BsFillBarChartFill />,
    cName: 'navbar-active-link'
  }
];

export default SidebarData;
