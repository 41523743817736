import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CertificateHandling from '../CertificateHandling/CertificateHandling';
import './RightSideBarPurchased.css';
import { useLoggedInUser } from '../../context/UserProvider';
import CertificatePop from '../CertificatePop/CertificatePop';
import certPrev from '../../assets/certPrev3.png';

export default function RightSideBarPurchased() {
  const [modalViewCert, setModalViewCert] = useState(false);
  const [modalPurchaseCert, setModalPurchaseCert] = useState(false);
  const { bestScore } = useLoggedInUser();

  const togglePurchaseCertModal = () => {
    setModalPurchaseCert(!modalPurchaseCert);
  };

  const toggleViewCertModal = () => {
    setModalViewCert(!modalViewCert);
  };

  return (
    <div className="rightSideBar" id="RightSideBarPurchased">
      <div className="cardBar">
        <Card className="purchasedCert" sx={{ borderRadius: 5 }}>
          <CardContent>
            <div className="imagePreview">
              <img
                className="purchasedCertPrev"
                src={certPrev}
                alt="certificate sample preview"
              />
            </div>
          </CardContent>
        </Card>
        <div className="purchasedCardContentsContainer">
          <div
            style={{ fontSize: '18px', textAlign: 'center', marginTop: '2rem' }}
          >
            <p style={{ margin: 0 }}>Your highest level is:</p>
            <span style={{ fontWeight: 800 }}>
              CEFR {bestScore && bestScore.overallScore.level}
            </span>
          </div>
          <div>
            <p className="textEnd">
              Your highest overall score from the BELA assessment will populate
              into your purchased certificate.
            </p>
          </div>
          <div className="purchasedCertBtns">
            <button
              className="btnGet"
              onClick={togglePurchaseCertModal}
              disabled={!bestScore}
            >
              Get my certificate
            </button>
            <button className="viewCertBtn" onClick={toggleViewCertModal}>
              View my certificate
            </button>
          </div>
        </div>
      </div>
      {modalViewCert && (
        <CertificateHandling toggleModal={toggleViewCertModal} />
      )}
      {modalPurchaseCert && (
        <CertificatePop toggleModal={togglePurchaseCertModal} />
      )}
    </div>
  );
}
