import React from 'react';
import Icon from '../../assets/index';
import './FooterTest.css';
import { useTheme } from '../../context/ThemeProvider';

export default function FooterTest(props) {
  const theme = useTheme();
  return (
    <div
      className={props.footerClass}
      style={{
        color: props.foregroundColor,
        backgroundColor: props.backgroundColor
      }}
    >
      <div className={props.copyRightClass}>
        <div className="copyrightAndTitle" style={{ marginRight: '2rem' }}>
          <a
            href="https://www.speakhabla.com"
            className="companyTitle"
            style={{
              marginRight: '1rem',
              fontWeight: 'bold',
              textDecoration: 'none',
              color: props.foregroundColor
            }}
          >
            SPEAKHABLA
          </a>
          <div className="copyrightTextt">
            &copy; 2022 Costa Rica. All rights reserved
          </div>
        </div>
        <div className="SocialMediaIcons">
          <a
            href="https://twitter.com/speak_habla"
            style={{ color: props.foregroundColor }}
          >
            <Icon.Twitter style={{ marginRight: '1rem' }} />
          </a>
          <a
            href="https://www.facebook.com/SpeakHabla/"
            style={{ color: props.foregroundColor }}
          >
            <Icon.Facebook style={{ marginRight: '1rem' }} />
          </a>
          <a
            href="https://www.linkedin.com/company/speakhabla-english-solutions"
            style={{ color: props.foregroundColor }}
          >
            <Icon.Linkedin style={{ marginRight: '1rem' }} />
          </a>
        </div>
      </div>
      <div className="developerInfo">
        <div style={{ marginRight: '0.5rem' }}>Developed By</div>
        <Icon.MegaDev
          style={{
            marginRight: '0.5rem',
            height: 20,
            fill: props.foregroundColor
          }}
        />
        <div
          style={{
            marginRight: '0.5rem',
            fontWeight: 'bold',
            color: props.foregroundColor
          }}
        >
          MegaDevs,
        </div>
        <div>Algonquin College</div>
      </div>
    </div>
  );
}
