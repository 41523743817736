import { textAlign } from '@mui/system';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import certificate from '../../assets/BELACertificate-noText.png';

const PDFFile = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ width: '100%', height: '100%', position: 'relative' }}
    id="my-node"
  >
    <Helmet>
      <title>Bela certificate Preview</title>

      <meta name="image" property="og:image" content={certificate} />
    </Helmet>

    <img src={certificate} alt="certificate" style={{ objectFit: 'contain' }} />
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        textAlign: 'center',
        top: '40%',
        left: '44%'
      }}
    >
      <div
        style={{
          paddingBottom: '1rem',
          fontFamily: 'Source Serif Pro'
        }}
      >
        {' '}
        This certifies that
      </div>
      <div
        style={{
          paddingBottom: '1rem',
          fontFamily: 'Source Serif Pro',
          fontWeight: '900',
          fontSize: '24px'
        }}
      >
        {props.userData.firstName} {props.userData.lastName}
      </div>
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '1rem',
          fontFamily: 'Source Serif Pro'
        }}
      >
        in accordance with the Common European Framework has successfully
        acheived a{' '}
      </div>
      <div
        style={{
          fontFamily: 'Source Serif Pro',
          fontWeight: '900',
          fontSize: '24px'
        }}
      >
        {props.userData.level}
      </div>
    </div>
    <div
      style={{
        position: 'absolute',
        fontFamily: 'Source Serif Pro',
        fontSize: '0.65rem',
        top: '90%',
        left: '12%',
        color: 'white',
        width: '200px'
      }}
    >
      Issued on: {props.userData.dateIssued}
    </div>
  </div>
));

export default PDFFile;
