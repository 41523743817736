import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import AdminSideBar from '../../AdminSideBar/AdminSideBar';
import AdminEditToggle from '../../AdminEditToggle/AdminEditToggle';
import AdminUploadMedia from '../../AdminUploadMedia/AdminUploadMedia';
import TestsTaken from '../../TestsTaken/TestsTaken';
import PageTitle from '../../PageTitle/PageTitle';
import './AdminHome.css';
import QuestionTypes from '../../QuestionTypes/QuestionTypes';
import { useTheme } from '../../../context/ThemeProvider';
import {
  countQuestionsDB,
  getAllTestTakers
} from '../../../utils/firebase-utils';
import { useLoggedInUser } from '../../../context/UserProvider';

export default function AdminHome() {
  const theme = useTheme();
  const [modal, setModal] = useState(false);
  const [usersDataArray, setUsersDataArray] = useState(null);
  const { loggedInUser } = useLoggedInUser();
  console.log(loggedInUser);

  useEffect(async () => {
    const data = await countQuestionsDB([
      'grammarQuestions',
      'readingQuestions',
      'listeningQuestions'
    ]);
  }, [modal]);

  useEffect(async () => {
    const tableArray = await getAllTestTakers();
    setUsersDataArray(tableArray);
  }, []);

  return (
    <div
      className="AdminHome"
      style={{ backgroundColor: theme.colors.dashboardBackground }}
    >
      <AdminSideBar />
      <Box className="adminAssessmentComponentsContainer">
        <PageTitle text="Manage Assessment" />
        <div>
          <div className="adminComponents">
            <h2>BELA Assessment Questions</h2>
            <AdminEditToggle setModal={setModal} />
          </div>
          <QuestionTypes />
        </div>
        {modal && <AdminUploadMedia setOpenModal={setModal} />}
        <TestsTaken
          employees={usersDataArray}
          setEmployees={setUsersDataArray}
        />
      </Box>
    </div>
  );
}
