import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { Link, useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import './SignIn.css';

import Visibility from '@mui/icons-material/Visibility';

import VisibilityOff from '@mui/icons-material/VisibilityOff';

import InputAdornment from '@mui/material/InputAdornment';

import { authenticateUser, encryptData } from '../../utils/firebase-utils';

import { useTheme } from '../../context/ThemeProvider';

import SignInDecor from '../../assets/signInDecor.png';
import SignInSlogan from '../../assets/signInSlogan.png';

import LargeButton from '../LargeButton/LargeButton';
import { useLoggedInUser } from '../../context/UserProvider';

export default function SignIn(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState({
    text: '',
    isValid: false,
    message: ''
  });
  const [password, setPassword] = useState({
    text: '',
    isValid: false,
    message: ''
  });
  const theme = useTheme();

  const navigate = useNavigate();

  // destructuring loggedIn user values
  const { setLoggedInUser, setBestScore, setLatestScore, setTestsTaken } = useLoggedInUser();

  const convertEmployees = (eps) => {
    const _list = [];
    eps.map((e) => {
      const el = JSON.parse(JSON.stringify(e));
      el.id = e.id;
      return _list.push(el);
    });
    return _list;
  };

  async function signInUser(ev) {
    ev.preventDefault();
    await authenticateUser(email.text, password.text).then((user) => {
      if (user !== undefined) {
        props.setSignedIn(true);
        if (user.type.text === 'organization') {
          const employees = convertEmployees(user.employees);
          user.employees = employees;
        }
        setLoggedInUser(user);

        // navigation based on user type
        switch (user.type.text) {
          case 'admin':
            navigate('/admin');
            break;
          case 'individual':
            navigate('/home');
            break;
          case 'organization':
            navigate('/organization');
            break;
          default:
            navigate('/');
            break;
        }
        return user;
      }
    });
  }

  function togglePassword() {
    setPasswordShown(!passwordShown);
  }

  useEffect(() => {
    setLoggedInUser(null);
    setBestScore(null);
    setLatestScore(null);
    setTestsTaken(null);
  }, []);

  return (
    <div>
      <Helmet>
        <title>BELA - Sign In</title>
      </Helmet>
      <div
        className="signInPage"
        style={{ backgroundColor: theme.colors.main }}
      >
        <div>
          <img
            src={SignInDecor}
            className="signInDecor"
            alt="Decorative element"
            loading="lazy"
          />
        </div>
        <div>
          <img
            src={SignInSlogan}
            alt="It Starts here with BELA. Certify your English level for employability."
            className="signInSlogan"
            loading="lazy"
          />
        </div>
        <div className="signInLeftHalf" />
        <div className="signInRightHalf">
          <div className="signInBox">
            <h1 className="signInTitle"> Welcome to BELA </h1>
            <p className="signInSubTitle">
              Get an accurate CEFR level and become English certified with our
              <strong> Business English Level Assessment.</strong>
            </p>

            <FormControl variant="outlined" className="textFieldContainer">
              <InputLabel htmlFor="outlined-required" className="label">
                Email
              </InputLabel>
              <OutlinedInput
                sx={{ borderRadius: '11px' }}
                required
                id="outlined-required"
                label="Email"
                className="emailField"
                onChange={(ev) => {
                  setEmail({
                    text: ev.target.value,
                    isValid: true,
                    message: ''
                  });
                }}
              />
            </FormControl>
            <div className="errorMessage" style={{ color: theme.colors.error }}>
              {email.message}
            </div>
            <FormControl variant="outlined" className="textFieldContainer">
              <InputLabel
                htmlFor="outlined-adornment-password"
                className="label"
              >
                Password
              </InputLabel>
              <OutlinedInput
                sx={{ borderRadius: '11px' }}
                required
                className="passwordField"
                id="outlined-adornment-password"
                type={passwordShown ? 'text' : 'password'}
                onChange={(ev) => {
                  setPassword({
                    text: ev.target.value,
                    isValid: true,
                    message: ''
                  });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        togglePassword();
                      }}
                      onMouseDown={() => {
                        togglePassword();
                      }}
                      edge="end"
                    >
                      {passwordShown ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <button className="forgotButton">
              <Link to="forgotPassword" style={{ color: 'black' }}>
                Forgot Password?
              </Link>
            </button>
            <div
              className="passwordErrorMessage"
              style={{ color: theme.colors.error }}
            >
              {password.message}
            </div>
            <div>
              <LargeButton
                text="Sign In"
                className="signInButton"
                onClick={(ev) => {
                  ev.preventDefault();
                  signInUser(ev);
                }}
              />
              <div className="contactRow">
                <div>Dont have an account?</div>
                <button className="signUpButton">
                  <Link to="/signUp" style={{ color: 'black' }}>
                    Sign Up
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
