import React from 'react';
import './LargeButton.css';

export default function LargeButton({ text, onClick, style, className }) {
  return (
    <button
      style={style}
      className={`largeButton  ${className}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
