import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// import { Link } from 'react-router-dom';

import './SignUp.css';

import CheckIcon from '@mui/icons-material/Check';

import ClearIcon from '@mui/icons-material/Clear';

import ErrorIcon from '@mui/icons-material/Error';

import TextField from '@mui/material/TextField';

import FormHelperText from '@mui/material/FormHelperText';

import IconButton from '@mui/material/IconButton';

import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';

import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useNavigate } from 'react-router-dom';

import USER_TYPES from '../../service/USER_TYPES';

import { signUpUser, emailTakenCheck } from '../../utils/firebase-utils';

import { useTheme } from '../../context/ThemeProvider';

// import ConfirmRegistration from './ConfirmRegistration';

import LargeButton from '../LargeButton/LargeButton';

import MediumButton from '../MediumButton/MediumButton';

export default function SignUp({
  setTypeOfCurrentUser,
  typeOfCurrentUser,
  setSignedIn
}) {
  const [showPasswordReq, setShowPasswordReq] = useState(false);
  const [passwordReq1, setPasswordReq1] = useState(false);
  const [passwordReq2, setPasswordReq2] = useState(false);
  const [passwordReq3, setPasswordReq3] = useState(false);
  const [passwordReq4, setPasswordReq4] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const theme = useTheme();
  const [checked, setChecked] = useState({
    individual: false,
    organization: false
  });
  const [isValidType, setIsValidType] = useState({
    text: '',
    isValid: false,
    message: ''
  });
  const [isValidFirst, setIsValidFirst] = useState({
    text: '',
    isValid: true,
    message: ''
  });
  const [isValidLast, setIsValidLast] = useState({
    text: '',
    isValid: true,
    message: ''
  });
  const [isValidEmail, setIsValidEmail] = useState({
    text: '',
    isValid: true,
    message: ''
  });
  const [isValidJob, setIsValidJob] = useState({
    text: '',
    isValid: true,
    message: ''
  });
  const [isValidCompanyName, setIsValidCompanyName] = useState({
    text: '',
    isValid: true,
    message: ''
  });
  const [isValidPassword, setIsValidPassword] = useState({
    text: '',
    isValid: true,
    message: ''
  });
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState({
    text: '',
    isValid: true,
    message: ''
  });
  const [isValidConsent1, setIsValidConsent1] = useState({
    isValid: false,
    message: ''
  });
  const [isValidConsent2, setIsValidConsent2] = useState({
    isValid: false,
    message: ''
  });
  const [isVerifiedEmail, setIsVerifiedEmail] = useState({
    isValid: false,
    message: ''
  });

  // user data object for individuals that will be saved on the database
  const userData = {
    isVerified: isVerifiedEmail.isValid,
    email: isValidEmail.text,
    name: {
      first: isValidFirst.text,
      last: isValidLast.text
    },
    username: isValidEmail.text,
    has_purchased_certificate: false,
    jobTitle: isValidJob.text,
    type: isValidType,
    companyName: isValidCompanyName.text,
    tests: {
      tests_taken: [],
      assigned: []
    },
    terms_conditions: isValidConsent1.isValid,
    marketing_consent: isValidConsent2.isValid
  };

  // organization user data object that will be saved on the database
  const organizationData = {
    isVerifiedEmail: isVerifiedEmail.text,
    email: isValidEmail.text,
    name: {
      first: isValidFirst.text,
      last: isValidLast.text
    },
    username: isValidEmail.text,
    jobTitle: isValidJob.text,
    type: isValidType,
    companyName: isValidCompanyName.text,
    // randomly generated company code
    companyCode: `MEGA${Math.floor(Math.random() * 10000)}`,
    employees: [],
    terms_conditions: isValidConsent1.isValid,
    marketing_consent: isValidConsent2.isValid
  };

  async function validateEmail() {
    // if user has used an existing email
    const inputData = isValidEmail.text;
    const inputDomain = inputData.split('@');

    if (inputDomain[1] != null && isValidType.text === 'organization' && emailTakenCheck(inputData)) {
      // If using one of the major email companies , ask them to use their work email
      switch (inputDomain[1]) {
        case 'yahoo.com':
        case 'gmail.com':
        case 'hotmail.com':
          setIsValidEmail({
            text: inputData,
            isValid: false,
            message: 'Please use your work email'
          });
          break;
        default:
          setIsValidEmail({
            text: inputData,
            isValid: true,
            message: ''
          });
      }
    } else if (inputDomain[1] != null && isValidType.text === 'individual') {
      setIsValidEmail({
        text: inputData,
        isValid: true,
        message: ''
      });
    } else {
      setIsValidEmail({
        text: inputData,
        isValid: false,
        message: 'Email is required'
      });
    }
    if (await emailTakenCheck(inputData) === true) {
      setIsValidEmail({
        text: inputData,
        isValid: false,
        message: 'Email is already registered'
      });
    } else {
      setIsValidEmail({
        text: inputData,
        isValid: true,
        message: ''
      });
    }
  }

  function validateConfirmPassword() {
    if (isValidConfirmPassword.text === '') {
      setIsValidConfirmPassword({
        text: '',
        isValid: false,
        message: 'Confirm Password is required'
      });
    } else if (
      isValidConfirmPassword.text === isValidPassword.text &&
      isValidConfirmPassword.text !== ''
    ) {
      setIsValidConfirmPassword({
        text: isValidConfirmPassword.text,
        isValid: true,
        message: ''
      });
    } else {
      setIsValidConfirmPassword({
        text: isValidConfirmPassword.text,
        isValid: false,
        message: 'Passwords do not match'
      });
    }
  }
  function validateType() {
    if (isValidType.text === '') {
      setIsValidType({
        text: '',
        isValid: false,
        message: 'Please select a type'
      });
    } else if (
      isValidType.text === 'individual' ||
      isValidType.text === 'organization'
    ) {
      setIsValidType({
        text: isValidType.text,
        isValid: true,
        message: ''
      });
    }
  }
  function validateFirstName() {
    if (isValidFirst.text === '') {
      setIsValidFirst({
        text: '',
        isValid: false,
        message: 'First Name is required'
      });
    }
  }
  function validateLastName() {
    if (isValidLast.text === '') {
      setIsValidLast({
        text: '',
        isValid: false,
        message: 'Last Name is required'
      });
    }
  }
  function validateJob() {
    if (isValidFirst.text === '') {
      setIsValidJob({
        text: '',
        isValid: false,
        message: 'Job Title is required'
      });
    }
  }
  function validateCompanyName() {
    if (isValidFirst.text === '') {
      setIsValidCompanyName({
        text: '',
        isValid: false,
        message: 'Company name is required'
      });
    }
  }
  function validatePassword() {
    // IF EMPTY
    if (isValidPassword.text === '') {
      setIsValidPassword({
        text: '',
        isValid: false,
        message: 'Password does not meet requirements.'
      });
      setPasswordReq1(false);
      setPasswordReq2(false);
      setPasswordReq3(false);
      setPasswordReq4(false);
      // IF has 8 or more characters
    } else {
      // If Captial letter exists
      const upperCaseLetters = /[A-Z]/g;
      if (isValidPassword.text.match(upperCaseLetters)) {
        setPasswordReq1(true);
      } else {
        setPasswordReq1(false);
      }
      // If Number exists
      const numbers = /[0-9]/g;
      if (isValidPassword.text.match(numbers)) {
        setPasswordReq2(true);
      } else {
        setPasswordReq2(false);
      }
      // If Special character exists
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if (isValidPassword.text.match(specialChars)) {
        setPasswordReq3(true);
      } else {
        setPasswordReq3(false);
      }

      // If text is 8 or more characters
      if (isValidPassword.text.length >= 8) {
        console.log('password has 8 characters or more');
        // Loop through the characters
        setPasswordReq4(true);
      } else {
        // text is less than 8 characters
        setPasswordReq4(false);
        setIsValidPassword({
          text: isValidPassword.text,
          isValid: false,
          message: 'Password does not meet requirements'
        });
      }
    }

    // If all the requirements are met
    if (passwordReq1 && passwordReq2 && passwordReq3 && passwordReq4) {
      setIsValidPassword({
        text: isValidPassword.text,
        isValid: true,
        message: ''
      });
    } else {
      setIsValidPassword({
        text: isValidPassword.text,
        isValid: false,
        message: ''
      });
    }
  }
  function validateConsent() {
    if (isValidConsent1.isValid === false) {
      setIsValidConsent1({
        isValid: false,
        message: 'Consent is required'
      });
    } else if (isValidConsent1.isValid === true) {
      setIsValidConsent1({
        isValid: true,
        message: ''
      });
    }
    if (isValidConsent2.isValid === false) {
      setIsValidConsent2({
        isValid: true,
        message: ''
      });
    } else if (isValidConsent1.isValid === true) {
      setIsValidConsent2({
        isValid: true,
        message: ''
      });
    }
  }
  function validateForm() {
    validateType();
    validateFirstName();
    validateLastName();
    validateJob();
    validatePassword();
    validateConsent();
    validateCompanyName();
    validateEmail();
    validateConfirmPassword();
    setShowPasswordReq(true);
  }

  const navigate = useNavigate();

  function allFieldsValidation() {
    if (isValidType.text === 'individual') {
      if (
        isValidFirst.isValid &&
        isValidLast.isValid &&
        isValidEmail.isValid &&
        isValidJob.isValid &&
        isValidPassword.isValid &&
        isValidConfirmPassword.isValid &&
        isValidConsent1.isValid &&
        isValidConsent2.isValid
      ) {
        return true;
      }
    } else if (isValidType.text === 'organization') {
      if (
        isValidFirst.isValid &&
        isValidLast.isValid &&
        isValidEmail.isValid &&
        isValidCompanyName.isValid &&
        isValidPassword.isValid &&
        isValidConfirmPassword.isValid &&
        isValidConsent1.isValid &&
        isValidConsent2.isValid
      ) {
        return true;
      }
    }
    return false;
  }
  async function signUpFunc() {
    validateForm();
    const isValidForm = allFieldsValidation();
    if (isValidForm) {
      setTypeOfCurrentUser(isValidType.text);
      //conditionally sending a different data to the database, based on the user type
      const dataToSend =
        isValidType.text === 'organization' ? organizationData : userData;
      signUpUser(isValidEmail.text, isValidPassword.text, dataToSend);
      setSignedIn(false);

      // passing the email as state to the next page, use the form field here to capture the right email
      // navigate('/signUp/verifyEmail', { state: isValidEmail.text });
      navigate('/');
    }
  }
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setIsDesktop(true);
      } else if (window.innerWidth < 900) {
        setIsDesktop(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isValidPassword.text !== '') {
      validatePassword();
    }
  }, [
    isValidPassword.text,
    passwordReq1,
    passwordReq2,
    passwordReq3,
    passwordReq4
  ]);

  return (
    <div className="signUpPage" style={{ backgroundColor: theme.colors.white }}>
      <Helmet>
        <title>BELA - Sign Up</title>
      </Helmet>
      <div className="signUpBox">
        <div className="signUpTitle"> Sign Up </div>
        <div className="signUpForm">
          <div className="formColumn">
            <p className="formColumn-instructions">
              Choose Your User Type
              <span> *</span>
            </p>
            <div className="formRow">
              <div className="leftHalf">
                <div
                  className={
                    checked.individual
                      ? 'userTypeBox activeLabel'
                      : 'userTypeBox'
                  }
                >
                  <input
                    type="radio"
                    name="individual"
                    value="individual"
                    id="individual"
                    checked={checked.individual}
                    onChange={() => {
                      setChecked({ individual: true, organization: false });
                      setIsValidType({
                        text: `${USER_TYPES.INDIVIDUAL}`,
                        isValid: true,
                        message: ''
                      });
                    }}
                  />
                  <label htmlFor="individual">
                    <div className="userTypeBoxText">Individual User</div>
                    <div className="userTypeBoxSubText">
                      I am a business professional who wishes to receive my CEFR
                      level certification
                    </div>
                  </label>
                </div>
              </div>
              <div className="rightHalf">
                <div
                  className={
                    checked.organization
                      ? 'userTypeBox activeLabel'
                      : 'userTypeBox'
                  }
                >
                  <input
                    className="signUpInput"
                    type="radio"
                    name="organization"
                    value="organization"
                    id="organization"
                    checked={checked.organization}
                    onChange={() => {
                      setChecked({ individual: false, organization: true });
                      setIsValidType({
                        text: `${USER_TYPES.ORGANIZATION}`,
                        isValid: true,
                        message: ''
                      });
                    }}
                  />

                  <label htmlFor="organization">
                    <div className="userTypeBoxText">Organizational User</div>
                    <div className="userTypeBoxSubText">
                      I am a point of contact of a company. I wish to track the
                      test results of my employees
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="errorMessage" style={{ color: theme.colors.error }}>
              {isValidType.message}
            </div>
          </div>
          <div>
            <div className="formColumn">
              <p className="formColumn-instructions">
                Profile Information
                <span> *</span>
              </p>
              <div className="formRow">
                <div className="leftHalf">
                  <TextField
                    value={isValidFirst.text}
                    required
                    label="First Name"
                    className="inputInput"
                    InputProps={{ style: { fontFamily: 'Nunito' } }}
                    InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
                    FormHelperTextProps={{
                      style: { fontFamily: 'Nunito', fontSize: '14px' }
                    }}
                    helperText={isValidFirst.message}
                    error={!isValidFirst.isValid}
                    onChange={(ev) => {
                      setIsValidFirst({
                        text: ev.target.value,
                        isValid: true,
                        message: ''
                      });
                    }}
                  />
                  {isValidType.text === 'individual' && (
                    <FormHelperText
                      className="componentHelperText"
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Nunito'
                      }}
                    >
                      <ErrorIcon
                        fontSize="small"
                        sx={{
                          marginRight: 1,
                          color: theme.colors.greyIcons
                        }}
                      />
                      The first and last name that you enter will be used to
                      generate your CEFR certificate.
                    </FormHelperText>
                  )}
                </div>
                <div className="rightHalf">
                  <TextField
                    value={isValidLast.text}
                    className="inputInput"
                    required
                    label="Last Name"
                    helperText={isValidLast.message}
                    error={!isValidLast.isValid}
                    InputProps={{ style: { fontFamily: 'Nunito' } }}
                    InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
                    FormHelperTextProps={{
                      style: { fontFamily: 'Nunito', fontSize: '14px' }
                    }}
                    onChange={(ev) => {
                      setIsValidLast({
                        text: ev.target.value,
                        isValid: true,
                        message: ''
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="formColumn">
              <div className="formRow">
                <div className="leftHalf">
                  <TextField
                    value={isValidEmail.text}
                    required
                    id="outlined-required"
                    label="Email"
                    className="inputInput"
                    placeholder="Email"
                    helperText={isValidEmail.message}
                    error={!isValidEmail.isValid}
                    InputProps={{ style: { fontFamily: 'Nunito' } }}
                    InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
                    FormHelperTextProps={{
                      style: { fontFamily: 'Nunito', fontSize: '14px' }
                    }}
                    onChange={(ev) => {
                      setIsValidEmail({
                        text: ev.target.value,
                        isValid: true,
                        message: ''
                      });
                    }}
                  />
                </div>
                <div className="rightHalf">
                  {isValidType.text === 'individual' ? (
                    <TextField
                      value={isValidJob.text}
                      className="inputInput"
                      required
                      label="Job Title"
                      helperText={isValidJob.message}
                      error={!isValidJob.isValid}
                      InputProps={{ style: { fontFamily: 'Nunito' } }}
                      InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
                      FormHelperTextProps={{
                        style: { fontFamily: 'Nunito', fontSize: '14px' }
                      }}
                      onChange={(ev) => {
                        setIsValidJob({
                          text: ev.target.value,
                          isValid: true,
                          message: ''
                        });
                      }}
                    />
                  ) : (
                      <TextField
                        value={isValidCompanyName.text}
                        className="inputInput"
                        required
                        label="Company Name"
                        helperText={isValidCompanyName.message}
                        error={!isValidCompanyName.isValid}
                        InputProps={{ style: { fontFamily: 'Nunito' } }}
                        InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
                        FormHelperTextProps={{
                          style: { fontFamily: 'Nunito', fontSize: '14px' }
                        }}
                        onChange={(ev) => {
                          setIsValidCompanyName({
                            text: ev.target.value,
                            isValid: true,
                            message: ''
                          });
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="leftHalf">
                <TextField
                  required
                  label="Password"
                  className="inputInput"
                  type={showPassword ? 'text' : 'password'}
                  value={isValidPassword.text}
                  helperText={isValidPassword.message}
                  error={!isValidPassword.isValid}
                  InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
                  FormHelperTextProps={{
                    style: { fontFamily: 'Nunito', fontSize: '14px' }
                  }}
                  onChange={(ev) => {
                    validatePassword();
                    setShowPasswordReq(true);
                    setIsValidPassword({
                      text: ev.target.value,
                      isValid: false,
                      message: ''
                    });
                  }}
                  InputProps={{
                    style: { fontFamily: 'Nunito' },
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {showPasswordReq ? (
                  <div className="passwordInstruction">
                    {isValidPassword.isValid ? (
                      <div
                        style={{
                          paddingBottom: '10px',
                          color: theme.colors.confirmation
                        }}
                      >
                        Password meets all requirements
                      </div>
                    ) : (
                        <div
                          style={{
                            paddingBottom: '10px',
                            color: theme.colors.error
                          }}
                        >
                          Password must meet all requirements
                      </div>
                      )}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        {passwordReq1 ? (
                          <CheckIcon
                            style={{ fill: theme.colors.confirmation }}
                          />
                        ) : (
                            <ClearIcon style={{ fill: theme.colors.error }} />
                          )}
                      </div>
                      <div style={{ paddingBottom: '5px' }}>
                        At least one uppercase letter
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        {passwordReq2 ? (
                          <CheckIcon
                            style={{ fill: theme.colors.confirmation }}
                          />
                        ) : (
                            <ClearIcon style={{ fill: theme.colors.error }} />
                          )}
                      </div>
                      <div style={{ paddingBottom: '5px' }}>
                        At least one number
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        {passwordReq3 ? (
                          <CheckIcon
                            style={{ fill: theme.colors.confirmation }}
                          />
                        ) : (
                            <ClearIcon style={{ fill: theme.colors.error }} />
                          )}
                      </div>
                      <div style={{ paddingBottom: '5px' }}>
                        At least one special character
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        {passwordReq4 ? (
                          <CheckIcon
                            style={{ fill: theme.colors.confirmation }}
                          />
                        ) : (
                            <ClearIcon style={{ fill: theme.colors.error }} />
                          )}
                      </div>
                      <div style={{ paddingBottom: '5px' }}>
                        Have 8 characters minimum
                      </div>
                    </div>
                  </div>
                ) : (
                    <div />
                  )}
              </div>
              <div className="rightHalf">
                <TextField
                  value={isValidConfirmPassword.text}
                  required
                  label="Re-enter Password"
                  className="inputInput"
                  type={showConfirmPassword ? 'text' : 'password'}
                  helperText={isValidConfirmPassword.message}
                  error={!isValidConfirmPassword.isValid}
                  InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
                  FormHelperTextProps={{
                    style: { fontFamily: 'Nunito', fontSize: '14px' }
                  }}
                  onChange={(ev) => {
                    setIsValidConfirmPassword({
                      text: ev.target.value,
                      isValid: true,
                      message: ''
                    });
                  }}
                  InputProps={{
                    style: { fontFamily: 'Nunito' },
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                              <VisibilityOff />
                            )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="consentList">
              <div className="consentColumn">
                <div className="consentRow">
                  <input
                    className="signUpInput"
                    type="checkbox"
                    id="accept"
                    name="accept"
                    value={isValidConsent1.isValid}
                    onChange={() => {
                      setIsValidConsent1({
                        isValid: !isValidConsent1.isValid,
                        message: ''
                      });
                    }}
                  />
                  <div className="consentLabel">
                    I consent that I have read and agreed to the Terms &amp;
                    Conditions
                    <span> *</span>
                  </div>
                </div>
                <div
                  className="errorMessage"
                  style={{ color: theme.colors.error }}
                >
                  {isValidConsent1.message}
                </div>
              </div>
              <div className="consentColumn">
                <div className="consentRow">
                  <input
                    className="signUpInput"
                    type="checkbox"
                    id="accept"
                    name="accept"
                    value={isValidConsent2.isValid}
                    onChange={() => {
                      setIsValidConsent2({
                        isValid: !isValidConsent2.isValid,
                        message: ''
                      });
                    }}
                  />
                  <div className="consentLabel">
                    I wish to receive promos, updates, and news letters from
                    BELA and SPEAKHABLA
                  </div>
                </div>
                <div
                  className="errorMessage"
                  style={{ color: theme.colors.error }}
                >
                  {isValidConsent2.message}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="callToActionButtons">
          {isDesktop ? (
            <LargeButton
              handlePress={signUpFunc}
              className="createButton"
              text="Create Account"
            />
          ) : (
              <MediumButton
                onClick={signUpFunc}
                className="createButton"
                text="Create Account"
              />
            )}
          <div className="contactRow">
            <div>Having problems?</div>
            <a
              href="https://speakhabla.com/contact/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <button className="contactButton">Contact Us</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
