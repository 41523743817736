import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './ResetPasswordForm.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LargeButton from '../LargeButton/LargeButton';
import { PASSWORD_RESET_STEPS } from '../../utils/constants';
import {
  sendPasswordResetLink,
  changeUserPassword
} from '../../utils/firebase-utils';

export default function ResetPasswordForm({ step }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setValid] = useState(true);
  const [isShortPassword, setShortPassword] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const handleVerifyPassword = () => {
    // handle matching passwords
    if (password === confirmPassword) {
      if (password.length > 5) {
        setValid(true);
        setShortPassword(false);
        const oobCode = params.get('oobCode');
        changeUserPassword(oobCode, password);
        navigate('/forgotPassword/success');
      } else {
        setShortPassword(true);
        setValid(true);
      }
    } else {
      setValid(false);
    }
  };

  const handleSendEmailLink = () => {
    sendPasswordResetLink(email);
    navigate(`/forgotPassword/resetLink?=${email}`);
  };

  const enterEmailStep = () => (
    <Box
      component="form"
      className="enterTextForm"
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSendEmailLink();
      }}
    >
      <h1>Forgot your password?</h1>
      <Box component="div" className="subtitle">
        <p>
          Please enter the email that you use to sign in to BELA.
          <br />
          We will use this email to send you a link to reset your password.
        </p>
      </Box>

      <TextField
        required
        id="outlined-required"
        label="Email"
        className="enterTextField"
        InputProps={{ style: { fontFamily: 'Nunito', fontSize: '16px' } }}
        InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
        onChange={(ev) => {
          setEmail(ev.target.value);
        }}
      />
      <LargeButton text="Submit" className="submitButton" />
    </Box>
  );

  const enterPasswordStep = () => (
    <Box
      component="form"
      className="enterTextForm"
      onSubmit={(ev) => {
        ev.preventDefault();
        handleVerifyPassword();
      }}
    >
      <h1>Reset Password</h1>
      <p className="subtitle">Please enter your new password.</p>
      <TextField
        required
        type="password"
        id="outlined-required"
        label="New Password"
        className="enterTextField"
        InputProps={{ style: { fontFamily: 'Nunito', fontSize: '16px' } }}
        InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
        onChange={(ev) => {
          setPassword(ev.target.value);
        }}
        sx={{ marginBottom: '3rem;' }}
      />
      <TextField
        required
        error={!isValid}
        type="password"
        id="outlined-required"
        label="Re-enter New Password"
        InputProps={{ style: { fontFamily: 'Nunito', fontSize: '16px' } }}
        InputLabelProps={{ style: { fontFamily: 'Nunito' } }}
        onChange={(ev) => {
          setConfirmPassword(ev.target.value);
        }}
        className="enterTextField"
      />
      <Box component="div" className="passwordErrorContainer">
        {!isValid && <p className="passwordError">Password does not match</p>}
        {isShortPassword && (
          <p className="passwordError">
            Password is too short. It has to be at least 6 characters.
          </p>
        )}
      </Box>
      <LargeButton text="Submit" className="submitButton" />
    </Box>
  );

  return (
    <React.Fragment>
      <Box component="div">
        {step === `${PASSWORD_RESET_STEPS.EMAIL}`
          ? enterEmailStep()
          : enterPasswordStep()}
      </Box>
      <Helmet>
        <title>BELA - Forgot Password</title>
      </Helmet>
    </React.Fragment>
  );
}
