import React from 'react';
import { Box } from '@mui/material';
import './StartAssessmentCard.css';
import { useNavigate } from 'react-router-dom';
import StartTestCardBackground from '../../assets/startTestCardBackground.png';
import MediumButton from '../MediumButton/MediumButton';
import { useTheme } from '../../context/ThemeProvider';
import { useLoggedInUser } from '../../context/UserProvider';

export default function StartAssessmentCard() {
  const theme = useTheme();

  //destructuring the data from the provider
  const { loggedInUser } = useLoggedInUser();
  const { tests } = loggedInUser;

  const navigate = useNavigate();

  function navigation() {
    navigate('/testAbout');
  }

  return (
    <Box
      style={{
        background: `url(${StartTestCardBackground})`,
        backgroundRepeat: 'none',
        backgroundSize: 'cover'
      }}
      className="cardContainerStartAssessment"
    >
      <span
        style={{ fontWeight: theme.typography.h1.fontWeight, fontSize: '40px' }}
        className="cardTitle"
      >
        Start with BELA
      </span>
      <p>
        Get your English level certified with our Business English Level
        Assessment.
      </p>
      <MediumButton
        text={
          tests && tests.tests_taken.length > 0
            ? 'Retake the assessment'
            : 'Take the assessment'
        }
        onClick={navigation}
      />
    </Box>
  );
}
