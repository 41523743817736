import React, { useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './RightSideBar.css';
import CertificatePop from '../CertificatePop/CertificatePop';
import RightSideBarPurchased from '../RightSideBarPurchased/RightSideBarPurchased';
import { useLoggedInUser } from '../../context/UserProvider';

export default function RightSideBar() {
  const [modal, setModal] = useState(false);
  const [beforePay, setBeforePay] = useState(null);
  const componentRef = useRef();

  const { testsTaken, bestScore, has_purchased_certificate } =
    useLoggedInUser();

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  return (
    <React.Fragment>
      <div className="myCertificateContainer">
        <div>
          <h2 className="myCertText">My Certificate</h2>
        </div>
        {has_purchased_certificate ? (
          <RightSideBarPurchased />
        ) : (
          <div className="rightSideBar">
            <div className="cardBar">
              {testsTaken ? (
                <Card className="certCard" sx={{ borderRadius: 5 }}>
                  <CardContent>
                    <div className="cardContent">
                      <p>Great work!</p>
                      <p className="textMid">You&apos;ve unlocked the</p>
                      <span style={{ fontWeight: 800, fontSize: '18px' }}>
                        {bestScore && bestScore.overallScore.level}
                      </span>
                    </div>
                  </CardContent>
                </Card>
              ) : (
                <Card className="emptyCertCard" sx={{ borderRadius: 5 }}>
                  <CardContent>
                    <div className="emptyCertCardContent">
                      <p>
                        Your CEFR Level is not available. Please take the
                        assessment
                      </p>
                    </div>
                  </CardContent>
                </Card>
              )}
              <div className="rightSideBarBottomContents">
                <div>
                  <p className="textEnd">
                    Your highest overall score from the BELA assessment will
                    populate into your purchased certificate.
                  </p>
                </div>
                <button
                  className="btnGet"
                  onClick={toggleModal}
                  disabled={!testsTaken}
                >
                  Get my certificate
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {modal && <CertificatePop toggleModal={toggleModal} />}
    </React.Fragment>
  );
}
