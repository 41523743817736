import React, { useEffect, useState } from 'react';
import { Routes, Route, useSearchParams, useLocation } from 'react-router-dom';
import { useTheme } from '../../context/ThemeProvider';

import './App.css';

import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';

import Header from '../Header/Header';

import Footer from '../Footer/Footer';

import ForgotPasswordPage from '../Pages/ForgotPasswordPage/ForgotPasswordPage';
import PasswordResetLink from '../Pages/PasswordResetLink/PasswordResetLink';
import PasswordResetSuccess from '../Pages/PasswordResetSuccess/PasswordResetSuccess';
import DashboardHome from '../Pages/DashboardHome/DashboardHome';
import { PASSWORD_RESET_STEPS } from '../../utils/constants';
import ConfirmRegistration from '../SignUp/ConfirmRegistration';
import VerifyEmailSuccess from '../Pages/VerifyEmailSuccess/VerifyEmailSuccess';
import { verifyEmailConfirm } from '../../utils/firebase-utils';
import OrganizationHome from '../Pages/OrganizationHome/OrganizationHome';
import AdminHome from '../Pages/AdminHome/AdminHome';

import AssessmentRulesPage from '../Pages/AssessmentRulesPage';
import TestConnectPage from '../Pages/TestConnectPage';
import AboutBelaPage from '../Pages/AboutBelaPage/AboutBelaPage';
import TestQuestionsPage from '../Pages/TestQuestionPage/TestQuestionsPage';
import HeaderTest from '../HeaderTest/HeaderTest';
import FooterTest from '../FooterTest/FooterTest';
import LoaderPage from '../Pages/LoaderPage/LoaderPage';

function App() {
  // Flag to display SignIn or SignUp based on top 2 buttons
  const [userSignIn, setUserSignIn] = useState(true);
  // String to keep track of the current user type
  const [typeOfCurrentUser, setTypeOfCurrentUser] = useState('');
  // Flag to display Individual, Organizational, Admin instead of SignIn or SignUp
  const [signedIn, setSignedIn] = useState(false);
  // state variable to render a different component if the user is verifying or changing password
  const [isChangePasswordPage, setChangePasswordPage] = useState(false);

  const theme = useTheme();

  // state variable to control the email verified status
  const [isEmailVerified, setEmailVerified] = useState(false);

  const location = useLocation();
  const [params] = useSearchParams();

  useEffect(() => {
    if (location.pathname === '/_/auth/action') {
      const mode = params.get('mode');
      if (mode === 'verifyEmail') {
        // verifying the email
        const oobCode = params.get('oobCode');
        verifyEmailConfirm(oobCode);
        setChangePasswordPage(false);
        setEmailVerified(true);
      } else {
        setChangePasswordPage(true);
      }
    }
  }, []);

  const currentLocation = location.pathname.split('/')[1];
  let footerClass;
  let copyRightClass;
  let headerClass;
  let footerBackground = theme.colors.dashboardBackground;
  let footerForeground = theme.colors.black;
  let headerBackground = theme.colors.dashboardBackground;
  let isSignInPage = false;

  switch (currentLocation) {
    case 'test':
      footerClass = 'noFooterDisplay';
      headerClass = 'belaSignInHeader';
      break;
    case 'home':
      footerClass = 'footerDashboardContainer';
      copyRightClass = 'copyrightAndSocialsHome';
      headerClass = 'noHeaderDisplay';
      break;
    case 'admin':
      footerClass = 'footerDashboardContainer';
      copyRightClass = 'copyrightAndSocialsHome';
      headerClass = 'noHeaderDisplay';
      break;
    case 'organization':
      footerClass = 'footerDashboardContainer';
      copyRightClass = 'copyrightAndSocialsHome';
      headerClass = 'noHeaderDisplay';
      break;
    case '':
      footerClass = 'footerLoginContainer';
      copyRightClass = 'copyrightAndSocialsSignIn';
      headerClass = 'belaSignInHeader';
      footerBackground = theme.colors.signInFooter;
      footerForeground = theme.colors.white;
      headerBackground = theme.colors.main;
      isSignInPage = true;
      break;
    default:
      footerClass = 'footerLoginContainer';
      copyRightClass = 'copyrightAndSocialsSignIn';
      headerClass = 'belaSignInHeader';
      break;
  }

  return (
    <div className="App">
      <div className="signInHeader">
        {currentLocation === 'home' ||
        currentLocation === 'admin' ||
        currentLocation === 'organization' ||
        currentLocation === '' ||
        currentLocation === 'forgotPassword' ||
        currentLocation === 'signUp' ? (
          <Header
            backgroundColor={headerBackground}
            isSignInPage={isSignInPage}
            setUserSignIn={setUserSignIn}
            headerClass={headerClass}
          />
        ) : (
          <HeaderTest
            headerClass={headerClass}
            backgroundColor={headerBackground}
          />
        )}
      </div>
      <div className="mainContent">
        <Routes>
          <Route
            path="/"
            element={
              <SignIn
                setTypeOfCurrentUser={setTypeOfCurrentUser}
                setSignedIn={setSignedIn}
                userSignIn={userSignIn}
                setUserSignIn={setUserSignIn}
              />
            }
          />
          <Route
            path="signUp"
            element={
              <SignUp
                setTypeOfCurrentUser={setTypeOfCurrentUser}
                typeOfCurrentUser={typeOfCurrentUser}
                setSignedIn={setSignedIn}
              />
            }
          />
          <Route
            path="forgotPassword"
            exact
            element={
              <ForgotPasswordPage
                step={PASSWORD_RESET_STEPS.EMAIL}
                userEmail="eduarddr96+bela1@gmail.com"
              />
            }
          />
          <Route
            path="_/auth/action"
            element={
              isChangePasswordPage ? (
                <ForgotPasswordPage step={PASSWORD_RESET_STEPS.PASSWORD} />
              ) : (
                <VerifyEmailSuccess isEmailVerified={isEmailVerified} />
              )
            }
          />
          <Route
            path="forgotPassword/resetLink"
            exact
            element={<PasswordResetLink />}
          />
          <Route
            path="forgotPassword/success"
            exact
            element={<PasswordResetSuccess />}
          />
          <Route
            path="signUp/verifyEmail/"
            exact
            element={<ConfirmRegistration />}
          />
          <Route path="admin//*" exact element={<AdminHome />} />
          <Route path="/home//*" exact element={<DashboardHome />} />
          <Route path="organization//*" exact element={<OrganizationHome />} />
          {/** Test Routes */}
          <Route path="/testAbout" element={<AboutBelaPage />} />
          <Route
            path="/volumeCheck"
            exact
            element={<LoaderPage step="volumeCheck" />}
          />
          <Route path="/testIntro" exact element={<LoaderPage step="test" />} />
          <Route path="/assessmentRules" element={<AssessmentRulesPage />} />
          <Route path="/testConnect" element={<TestConnectPage />} />
          <Route path="/testIntro" element={<LoaderPage step="test" />} />
          <Route path="/test" element={<TestQuestionsPage />} />
          <Route
            path="/results"
            element={<LoaderPage title="Calculating your results" />}
          />
        </Routes>
      </div>
      <FooterTest
        backgroundColor={footerBackground}
        foregroundColor={footerForeground}
        footerClass={footerClass}
        copyRightClass={copyRightClass}
      />
    </div>
  );
}

export default App;
