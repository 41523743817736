import React, { useState } from 'react';
import Link from '@mui/material/Link';
import CEFRLevelsEnglish from '../../assets/CEFREnglish.png';
import CEFRLevelsSpanish from '../../assets/CEFRSpanish.png';
import './CEFRLevels.css';
import EnFlag from '../../assets/EnFlag.jpg';
import EsFlag from '../../assets/EsFlag.jpg';
import { useTheme } from '../../context/ThemeProvider';

export default function CEFRLevels() {
  const [isEnglish, setIsEnglish] = useState(true);
  const theme = useTheme();
  return (
    <div className="CEFRLevels" style={{ marginTop: '80px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingBottom: '10px',
            justifyContent: 'space-between'
          }}
        >
          <h2
            style={{
              fontWeight: 800,
              fontSize: '24px',
              marginBottom: '20px',
              color: '#000000'
            }}
          >
            Meaning of CEFR Levels
          </h2>
          <div
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <button
              style={{
                border: 'none',
                backgroundColor: 'transparent'
              }}
              onClick={() => {
                setIsEnglish(!isEnglish);
              }}
            >
              {!isEnglish ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>EN |</div>
                  <img src={EnFlag} alt="en" style={{ paddingLeft: '10px' }} />
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>ES |</div>
                  <img src={EsFlag} alt="es" style={{ paddingLeft: '10px' }} />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      {isEnglish ? (
        <div className="CEFRcontentContainer">
          <div className="CEFRimageContainer">
            <img
              className="CEFRimage"
              src={CEFRLevelsEnglish}
              alt="CEFR English"
            />
          </div>
          <div className="CEFRlearnMore">
            <p>
              The Common European Framework of References for Languages (CEFR)
              makes it easy for employers to evaluate the English qualifications
              of candidates for employment.
            </p>
            <Link
              href="https://www.cambridgeenglish.org/exams-and-tests/cefr/#:~:text=The%20Common%20European%20Framework%20of%20Reference%20for%20Languages%20(CEFR)%20is,who%20have%20mastered%20a%20language"
              variant="outlined"
              className="learnMoreBtn"
              target="_blank"
              sx={{
                border: '1px solid #C2C6C7',
                color: '#000',
                borderRadius: 6,
                backgroundColor: 'transparent',
                textTransform: 'Capitalize',
                fontWeight: 'bold',
                alignSelf: 'center',
                alignContent: 'center',
                justifySelf: 'center',
                padding: '0.5rem 2rem 0.5rem 2rem'
              }}
            >
              Learn More
            </Link>
          </div>
        </div>
      ) : (
        <div className="CEFRcontentContainer">
          <div className="CEFRimageContainer">
            <img
              className="CEFRimage"
              src={CEFRLevelsSpanish}
              alt="CEFR Spanish"
            />
          </div>
          <div className="CEFRlearnMore">
            <p>
              El Marco Común Europeo de Referencia para los Idiomas (MCER)
              facilita a los empleadores la evaluación de las calificaciones en
              inglés de los candidatos para el empleo.
            </p>
            <Link
              href="https://www.cambridgeenglish.org/exams-and-tests/cefr/#:~:text=The%20Common%20European%20Framework%20of%20Reference%20for%20Languages%20(CEFR)%20is,who%20have%20mastered%20a%20language"
              variant="outlined"
              className="learnMoreBtn"
              target="_blank"
              sx={{
                border: '1px solid #C2C6C7',
                color: '#000',
                borderRadius: 6,
                backgroundColor: 'transparent',
                textTransform: 'Capitalize',
                fontWeight: 'bold',
                alignSelf: 'center',
                alignContent: 'center',
                justifySelf: 'center',
                padding: '0.5rem 2rem 0.5rem 2rem'
              }}
            >
              Aprende más
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
