import { Alert } from '@mui/material';
import { useTimer } from 'react-timer-hook';
import { useEffect, useCallback, useState, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { useTestContext } from '../../../context/TestProvider';
import { QUESTION_TYPES } from '../../../utils/constants';
import usePathname from '../../hooks/usePathname';
import { useBackListener } from '../../hooks/useBackListener';
import { useTheme } from '../../../context/ThemeProvider';
import TimesUpModal from '../../TimesUpModal/TimesUpModal';
import Loader from '../../Loader/Loader';

const Question = lazy(() => import('../../Question/Question'));

export default function TestQuestionsPage() {
  const {
    setOfQuestions,
    testType,
    progressValue,
    grammarQuestionsQty,
    readingQuestionsQty,
    listeningQuestionsQty,
    setProgressValue,
    setTestType,
    userProgress
  } = useTestContext();
  const [timesUp, setTimesUp] = useState(false);
  const [color, setColor] = useState('success');

  const setTimer = useCallback(() => {
    const time = new Date();
    time.setSeconds(2400); //40 minutes timer;2400
    return time;
  }, []);

  const { seconds, minutes } = useTimer({
    expiryTimestamp: setTimer(),
    onExpire: () => {
      console.warn('onExpire called');
      setTimesUp(true);
    }
  });

  useEffect(() => {
    let counter = 0;
    if (progressValue) {
      counter = parseInt(progressValue);
    }
    const interval = setInterval(() => {
      setProgressValue(() => {
        counter += 1;
        return counter;
      });
    }, 24000);

    //clean up function
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // 5 minutes left
    if (progressValue >= 87.5) {
      setColor('warning');
    }

    // 1 minute left
    if (progressValue >= 97.5) {
      setColor('danger');
    }
  }, [progressValue]);

  const [timeLeft, setTimeLeft] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const { boxShadows } = useTheme();

  const navigate = useNavigate();

  const pathname = usePathname();

  //listening for the back button navigation
  useBackListener(() => {
    navigate(pathname);
  });

  useEffect(() => {
    console.log({ progressValue });
    let timeoutOne = null;
    let timeoutTwo = null;

    // 5 minutes left
    setTimeout(() => setShowAlert(false), 8000);
    if (progressValue >= 87.5 && progressValue <= 87.9) {
      setTimeLeft(5);
      setShowAlert(true);
      timeoutOne = setTimeout(() => setShowAlert(false), 8000);
    }

    // 1 minute left
    if (progressValue >= 97.5 && progressValue <= 98.5) {
      setTimeLeft(1);
      setShowAlert(true);
      timeoutTwo = setTimeout(() => setShowAlert(false), 8000);
    }

    return () => {
      clearTimeout(timeoutOne);
      clearTimeout(timeoutTwo);
    };
  }, [progressValue]);

  function handleTimesUp() {
    switch (testType) {
      case QUESTION_TYPES.GRAMMAR:
        navigate('/testIntro', { state: 'We are saving your answers' });
        setTestType(QUESTION_TYPES.READING);
        break;
      case QUESTION_TYPES.READING:
        navigate('/testIntro', { state: 'We are saving your answers' });
        setTestType(QUESTION_TYPES.LISTENING);
        break;
      case QUESTION_TYPES.LISTENING:
        //passing the user progress as the state to the next page
        navigate('/results', { state: userProgress });
        setTestType(null);
        break;
      default:
        break;
    }
    //navigate to the test page, unmount the component
  }
  return (
    <div className="questionPageContainer">
      <div>
        {timesUp && <TimesUpModal handleTimesUp={handleTimesUp} />}
        <ProgressBar
          color={color}
          minutes={minutes}
          seconds={seconds}
          testType={testType}
          progressValue={progressValue}
        />
        <Suspense
          fallback={
            <div className="loaderContainer">
              <Loader />
            </div>
          }
        >
          <Question
            totalQuestions={
              testType === QUESTION_TYPES.GRAMMAR
                ? grammarQuestionsQty
                : testType === QUESTION_TYPES.READING
                ? readingQuestionsQty
                : listeningQuestionsQty
            }
            setOfQuestions={setOfQuestions && setOfQuestions}
          />
        </Suspense>
      </div>
      {showAlert && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Alert
            variant="standard"
            severity="warning"
            sx={{
              backgroundColor: '#FFF2E2',
              color: 'black',
              width: '70vw',
              boxShadow: boxShadows.main
            }}
          >
            You have <strong>{timeLeft} minute(s) </strong> remaining to
            complete this section of the assessment.
          </Alert>
        </div>
      )}
    </div>
  );
}
