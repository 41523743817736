import React from 'react';
import { Container } from '@mui/material';
import GrammarSVG from '../../assets/GrammarSVG.png';
import './QuestionTypes.css';
import { useTheme } from '../../context/ThemeProvider';
import ReadingSVG from '../../assets/ReadingSVG.png';
import ListeningSVG from '../../assets/ListeningSVG.png';

export default function QuestionTypes() {
  const theme = useTheme();
  return (
    <Container className="QuestionTypes" sx={{ paddingLeft: 0 }}>
      <div
        className="GrammarSection"
        style={{
          backgroundColor: theme.colors.white
        }}
      >
        <div
          className="sectionDescription"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              backgroundColor: theme.colors.dashboardBackground,
              borderRadius: '10px',
              padding: '1rem 1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100px',
              height: '100%'
            }}
          >
            <img src={GrammarSVG} alt="grammar icon" />
          </div>
          <div className="PartDescription">
            <div className="sectionTitles">
              <div
                style={{
                  fontSize: theme.typography.h5.fontSize
                }}
              >
                <span>Part 1</span>
              </div>
              <div
                className="PartTitle"
                style={{
                  fontSize: theme.typography.h2.fontSize,
                  fontWeight: theme.typography.h2.fontWeight
                }}
              >
                <span>Grammar and Vocabulary</span>
              </div>
            </div>
            <p>Total: 49 Questions</p>
          </div>
        </div>
      </div>

      <div
        className="ReadingSection"
        style={{
          backgroundColor: theme.colors.white
        }}
      >
        <div
          className="sectionDescription"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              backgroundColor: theme.colors.dashboardBackground,
              borderRadius: '10px',
              padding: '1rem 1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100px',
              height: '100%'
            }}
          >
            <img src={ReadingSVG} alt="grammar icon" />
          </div>
          <div className="PartDescription">
            <div className="sectionTitles">
              <div
                style={{
                  fontSize: theme.typography.h5.fontSize
                }}
              >
                <span>Part 2</span>
              </div>
              <div
                className="PartTitle"
                style={{
                  fontSize: theme.typography.h2.fontSize,
                  fontWeight: theme.typography.h2.fontWeight
                }}
              >
                <span>Reading</span>
              </div>
            </div>
            <p>Total: 56 Questions</p>
          </div>
        </div>
      </div>

      <div
        className="ListeningSection"
        style={{
          backgroundColor: theme.colors.white
        }}
      >
        <div
          className="sectionDescription"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              backgroundColor: theme.colors.dashboardBackground,
              borderRadius: '10px',
              padding: '1rem 1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100px',
              height: '100%'
            }}
          >
            <img src={ListeningSVG} alt="grammar icon" />
          </div>
          <div className="PartDescription">
            <div className="sectionTitles">
              <div
                style={{
                  fontSize: theme.typography.h5.fontSize
                }}
              >
                <span>Part 3</span>
              </div>
              <div
                className="PartTitle"
                style={{
                  fontSize: theme.typography.h2.fontSize,
                  fontWeight: theme.typography.h2.fontWeight
                }}
              >
                <span>Listening</span>
              </div>
            </div>

            <p>Total: 27 Questions</p>
          </div>
        </div>
      </div>
    </Container>
  );
}
