import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import RightSideBar from '../../RightSideBar/RightSideBar';
import './Home.css';
import PageTitle from '../../PageTitle/PageTitle';
import StartAssessmentCard from '../../StartAssessmentCard/StartAssessmentCard';
import ScoreTable from '../../ScoreTable/ScoreTable';

import { useLoggedInUser } from '../../../context/UserProvider';
import { queryDocsByRefs } from '../../../utils/firebase-utils';
import CertificateHandling from '../../CertificateHandling/CertificateHandling';

export default function Home() {
  const {
    loggedInUser,
    setTestsTaken,
    setBestScore,
    setLatestScore,
    certModal,
    toggleCertModal
  } = useLoggedInUser();

  const { tests, name } = loggedInUser;
  // once we have the loggedIn user we want to fetch the tests and other data
  useEffect(() => {
    if (Object.keys(tests).length > 0) {
      if (tests.tests_taken.length > 0) {
        queryDocsByRefs('test', tests.tests_taken).then((testsTaken) => {
          const newTestsTakenArr = testsTaken.map((test) => {
            const keys = Object.keys(test);
            const newArr = test[keys];

            return newArr;
          });

          setTestsTaken(newTestsTakenArr);

          // sorting the best score test and saving on the context object
          const sortedByBest = newTestsTakenArr.sort((a, b) => {
            if (a.overallScore.score > b.overallScore.score) {
              return -1;
            }
            if (a.overallScore.score < b.overallScore.score) {
              return 1;
            }
            return 0;
          });

          setBestScore(sortedByBest[0]);

          // sorting the latest score and saving on the context
          const sortedByDate = newTestsTakenArr.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );

          setLatestScore(sortedByDate[0]);
        });
      }
    }
  }, [loggedInUser]);
  return (
    loggedInUser && (
      <div className="Home">
        <Helmet>
          <title>BELA - Home</title>
        </Helmet>
        <div className="homeComponents">
          <PageTitle text={`Welcome Back ${name.first}!`} />
          <StartAssessmentCard />
          <ScoreTable />
        </div>
        <RightSideBar />
        {certModal && <CertificateHandling toggleModal={toggleCertModal} />}
      </div>
    )
  );
}
