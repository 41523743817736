import React, { useState } from 'react';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon from '../../assets/index';
import { useTheme } from '../../context/ThemeProvider';
import ExitWarningPop from '../ExitWarningPop/ExitWarningPop';
import { useTestContext } from '../../context/TestProvider';
import { QUESTION_TYPES } from '../../utils/constants';

import './HeaderTest.css';

export default function HeaderTest(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];

  const { backgroundColor, headerClass } = props;
  const [headerModal, setHeaderModal] = useState(false);
  const { setTestType, setFetchNewSet } = useTestContext();

  const toggleModal = () => {
    setHeaderModal(!headerModal);
  };

  const goHome = () => {
    setHeaderModal(false);
    setTestType(QUESTION_TYPES.GRAMMAR);
    //resetting the questions
    sessionStorage.setItem('data', null);
    sessionStorage.setItem('user', null);
    setFetchNewSet(false);
    navigate('/home');
  };

  const isResultsPage = () => {
    if (currentLocation === 'results') {
      goHome();
    } else toggleModal();
  };

  return (
    <div className={headerClass} style={{ backgroundColor }}>
      <Icon.BelaLogoStandard className="belaLogo" />
      <div className="signInLink" style={{ color: theme.colors.black }}>
        <Button style={{ color: theme.colors.black }} onClick={isResultsPage}>
          <CloseIcon fontSize="large" />
        </Button>
      </div>
      {headerModal && (
        <ExitWarningPop toggleModal={toggleModal} goHome={goHome} />
      )}
    </div>
  );
}
