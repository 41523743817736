import React from 'react';
import { useTheme } from '../../context/ThemeProvider';

const buttonStyles = {
  padding: '1rem',
  marginLeft: '0',
  cursor: 'pointer',
  fontWeight: 'bold',
  borderRadius: '15px',
  width: '100%',
  '&:active': {
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
  }
};

export default function SubmitButton(props) {
  const { typography, colors } = useTheme();
  return (
    <button
      aria-label="toggle password visibility"
      onClick={props.onClick}
      onMouseDown={props.onClick}
      edge="end"
      style={{
        ...buttonStyles,
        fontSize: typography.h4.fontSize,
        border: `1px solid ${colors.inactiveInputBorder}`,
        fontFamily: 'Nunito',
        backgroundColor: '#EDF3F5'
      }}
    >
      {props.text}
    </button>
  );
}
