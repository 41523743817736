import React, { useState, useRef } from 'react';
import {
  exportComponentAsPDF,
  exportComponentAsPNG
} from 'react-component-export-image';
import domtoimage from 'dom-to-image';
import './CertificateHandling.css';
import CloseIcon from '@mui/icons-material/Close';
import { FiDownload } from 'react-icons/fi';
import NewWindow from 'react-new-window';
import PDFFile from '../PDFFile/PDFFile';
import { useTheme } from '../../context/ThemeProvider';
import { useLoggedInUser } from '../../context/UserProvider';
import { uploadBase64ImageToDB } from '../../utils/firebase-utils';

export default function CertificateHandling(props) {
  const { bestScore, loggedInUser } = useLoggedInUser();
  const [userData, setUserData] = useState({
    firstName: loggedInUser.name.first,
    lastName: loggedInUser.name.last,
    level: bestScore.overallScore.level,
    dateIssued: bestScore.date
  });
  // Original Certificate size 2000 X 1414
  // Scaled 4 times down = 500 X 354
  const fileName = 'Bela-certificate';
  const html2CanvasOptions = {};
  const pdfOptions = {
    w: 500,
    h: 354,
    x: 65,
    y: 35,
    unit: 'px',
    orientation: 'l'
  };
  const theme = useTheme();
  const [frame, setFrame] = useState(null);
  const [isLinkedInOpened, setOpenedLinkedIn] = useState(false);
  const [certificateURL, setCertificateURL] = useState('sample');
  const certificateTitle = 'I just got an English Language certificate!';
  const certificateSource = 'This certificate is from BELA - SPEAKHABLA';
  const componentRef = useRef();

  const handleShareOnLinkedIn = async (htmlRef) => {
    const htmlNode = document.getElementById('my-node');

    domtoimage
      .toPng(htmlNode)
      .then((dataUrl) => {
        uploadBase64ImageToDB(dataUrl).then(async (previewLink) => {
          setCertificateURL(previewLink);
          setOpenedLinkedIn(true);
        });
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  };

  return (
    <div className="CertificateHandling">
      <div className="overlay" />
      <div className="modal-Prev-content">
        <div>
          <div
            className="purchasedCertificateTitle"
            style={{
              fontSize: theme.typography.h1.fontSize,
              fontWeight: theme.typography.h1.fontWeight,
              fontFamily: theme.typography.h1.fontFamily,
              paddingTop: '3rem'
            }}
          >
            My Certificate
          </div>
          <div className="imagePreview">
            <PDFFile ref={componentRef} userData={userData} />
          </div>
          <div className="btnHandler">
            <button
              className="btnPng"
              onClick={() =>
                exportComponentAsPDF(componentRef, {
                  fileName,
                  html2CanvasOptions,
                  pdfOptions
                })
              }
            >
              <FiDownload style={{ fontSize: '1.5rem' }} />
              Download Certificate
            </button>
            <button
              onClick={() => {
                handleShareOnLinkedIn(componentRef);
              }}
              className="btn-linkedin"
            >
              Share on LinkedIn{' '}
            </button>
          </div>
          {frame && <div dangerouslySetInnerHTML={{ __html: frame }} />}
          {isLinkedInOpened && (
            <NewWindow
              url={`https://www.linkedin.com/shareArticle/?mini=true&url=${certificateURL}&title=${certificateTitle}&source=${certificateSource}`}
              title="Share on LinkedIn"
              onUnload={() => setOpenedLinkedIn(false)}
            />
          )}
        </div>
        <CloseIcon
          className="close-modal"
          onClick={props.toggleModal}
          fontSize="large"
        />
      </div>
    </div>
  );
}
