import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LargeButton from '../../LargeButton/LargeButton';
import LanguageToggle from '../../LanguageToggle/LanguageToggle';
import { ABOUT_BELA_TRANSLATIONS } from '../../../utils/constants';
import './AboutBelaPage.css';

export default function AboutBelaPage() {
  const originalText = {
    pageTitle: 'About BELA',
    pageSubtitle:
      'You are about to start the Business English Level Assessment. The assessment is divided into three sections: ',
    rules: ['1. Grammar and Vocabulary', '2. Reading', '3. Listening'],
    buttonText: 'Okay'
  };

  const [language, setLanguage] = useState('ES');

  const navigate = useNavigate();

  function navigation() {
    navigate('/volumeCheck', { state: "Let's get you set up" });
  }

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        maxWidth: 700,
        height: '85vh'
      }}
    >
      <div className="langToggle">
        <LanguageToggle language={language} setLanguage={setLanguage} />
      </div>
      <Box className="cardContainerBelaPage">
        <h1 className="pageTitle">
          {language === 'ES'
            ? originalText.pageTitle
            : ABOUT_BELA_TRANSLATIONS.pageTitle}
        </h1>
        <Box component="div" className="subtitle">
          <p>
            {language === 'ES'
              ? originalText.pageSubtitle
              : ABOUT_BELA_TRANSLATIONS.pageSubtitle}
          </p>
        </Box>
        <Box component="div" className="testRules">
          {language === 'US'
            ? ABOUT_BELA_TRANSLATIONS.rules.map((rule, index) => (
                <p className="testRule" key={index}>
                  {rule}
                </p>
              ))
            : originalText.rules.map((rule, index) => (
                <p className="testRule" key={index}>
                  {rule}
                </p>
              ))}
        </Box>
        <Box component="div" sx={{ marginTop: '3rem' }}>
          <LargeButton
            text={
              language === 'ES'
                ? originalText.buttonText
                : ABOUT_BELA_TRANSLATIONS.buttonText
            }
            onClick={navigation}
          />
        </Box>
      </Box>
    </Container>
  );
}
