import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import AffiliatedUsers from '../../AffiliatedUsers/AffiliatedUsers';
import CompanyCode from '../../CompanyCode/CompanyCode';
import OverviewUsers from '../../OverviewUsers/OverviewUsers';
import Navbar from '../../Navbar/Navbar';
import PageTitle from '../../PageTitle/PageTitle';
import Footer from '../../Footer/Footer';
import './OrganizationHome.css';
import { useTheme } from '../../../context/ThemeProvider';
import {
  getAllTestTakersForCompanyCode
} from '../../../utils/firebase-utils';

import { useLoggedInUser } from '../../../context/UserProvider';
import OrgNavbar from '../../OrgNavbar/OrgNavbar';

export default function OrganizationHome() {
  const { loggedInUser } = useLoggedInUser();
  // employees is a firebase document
  const { employees } = loggedInUser;
  console.log(employees);
  const [employeesDataArray, setEmployeesDataArray] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const tableArray = await getAllTestTakersForCompanyCode(loggedInUser.employees);
    setEmployeesDataArray(tableArray);
  };

  return (
    <div className="OrganizationHome">
      <OrgNavbar />
      <Box className="orgComponentsContainer">
        <PageTitle text="Dashboard" />
        <div className="orgInfo">
          <CompanyCode companyCode={loggedInUser && loggedInUser.companyCode} />
          <OverviewUsers
            numberOfUsers={employees.length > 0 ? employees.length : 0}
          />
        </div>
        <AffiliatedUsers
          employees={employeesDataArray && employeesDataArray}
          setEmployees={setEmployeesDataArray}
        />
      </Box>
    </div>
  );
}
