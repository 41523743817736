import React from 'react';
import { NavLink } from 'react-router-dom';

import { ExitToApp } from '@mui/icons-material';
import Link from '@mui/material/Link';
import BelaLogo from '../../assets/belaLogo.png';
import AdminSidebarData from './AdminSideBarData';
import AdminHamburger from './AdminHamburger';
import './AdminSideBar.css';

function AdminSideBar() {
  return (
    <React.Fragment>
      <AdminHamburger />
      <div className="adminSideBar">
        <div className="content-wrapper">
          <img src={BelaLogo} className="adminBelaLogo" alt="bela logo" />
          <nav
            className="admin-nav-menu-items"
            style={{ margin: 0, width: '100%' }}
          >
            <div className="adminSideBar-wrapper">
              {AdminSidebarData.map((item, index) => (
                <NavLink
                  key={index}
                  id="admin-navLink"
                  className={item.cName}
                  to={item.path}
                  style={{
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontSize: 16,
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {item.icon}
                  <span>{item.title}</span>
                </NavLink>
              ))}
            </div>
          </nav>
          <div className="adminCardLogoutContainer">
            <Link href="/" underline="none">
              <div className="divLogOut">
                <div className="btnLogout">Logout</div>
                <div className="listIcon">
                  <ExitToApp />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminSideBar;
