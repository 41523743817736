import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/material';
import SmallButton from '../SmallButton/SmallButton';

export default function IntroductionContainer({
  titleText,
  instructionsText,
  checkBar,
  yesButtonText,
  step,
  ...props
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        height: 643,
        backgroundColor: 'white',
        boxShadow: 'rgb(149 157 165 / 15%) 0px 8px 24px',
        borderRadius: '15px'
      }}
    >
      <img src={checkBar} alt="volume bar" style={{ width: '70%' }} />
      <h1
        style={{
          fontSize: '32px',
          fontWeight: 'bold',
          marginTop: '5rem',
          color: '#000000',
          marginBottom: step === 'rulesPage' ? '1rem' : '3rem'
        }}
      >
        {titleText}
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: step === 'connectCodePage' && '9rem'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {props.children}
          {step === 'rulesPage' ? (
            <ul style={{ marginBottom: '2rem' }}>
              {instructionsText &&
                instructionsText.map((item) => (
                  <li
                    key={Math.random(0, 1)}
                    style={{ textAlign: 'left', lineHeight: 2 }}
                  >
                    <span style={{ marginLeft: '0.5rem', fontSize: '16px' }}>
                      {item}
                    </span>
                  </li>
                ))}
            </ul>
          ) : (
            <span
              role="button"
              style={{
                alignSelf: 'flex-end',
                fontWeight: 'bold',
                fontSize: step === 'connectCodePage' && '14px',
                textDecoration: 'underline',
                marginTop: step === 'connectCodePage' && '0.3rem',
                marginRight: step === 'connectCodePage' && '0.5rem'
              }}
              onClick={props.companyCodeAction}
              onKeyUp={props.companyCodeAction}
              tabIndex="0"
            >
              {instructionsText}
            </span>
          )}
        </div>
        {step === 'connectCodePage' && (
          <div
            style={{
              display: 'flex',
              marginBottom: '2.5rem',
              marginLeft: '1rem',
              alignItems: 'center'
            }}
          >
            {props.hasSubmittedCode === true ? (
              props.isCodeVerified ? (
                <React.Fragment>
                  <CheckCircleIcon color="success" />
                  <span style={{ marginLeft: '0.5rem' }}>
                    {props.verification}
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ErrorIcon color="error" />
                  <span style={{ marginLeft: '0.5rem' }}>Code Not Valid</span>
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                <CheckCircleIcon color="disabled" />
                <span style={{ marginLeft: '0.5rem' }}>Pending</span>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <div
        className="instructionBtnContainer"
        style={{
          display: 'flex',
          alignSelf: 'flex-end',
          flexGrow: 1,
          alignItems: 'flex-end',
          marginBottom: '2rem'
        }}
      >
        {props.noButtonAction && (
          <span
            role="button"
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              textDecoration: 'underline',
              marginRight: '2rem',
              marginBottom: '1rem',
              cursor: 'pointer'
            }}
            onClick={props.noButtonAction}
            onKeyUp={props.noButtonAction}
            tabIndex="0"
          >
            {props.noButtonText}
          </span>
        )}
        <SmallButton
          text={yesButtonText}
          style={{ marginRight: '2rem' }}
          onClick={props.yesButtonAction}
        />
      </div>
    </Box>
  );
}
