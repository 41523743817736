import React, { useState, useRef } from 'react';
import './AdminUploadMedia.css';
import CloseIcon from '@mui/icons-material/Close';
import * as FaIcons from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { handleUpload, getFileSize } from '../../utils/firebase-utils';

export default function AdminUploadMedia({ setOpenModal }) {
  const [image, setImage] = useState('');
  const [isOptimized, setIsOptimized] = useState(false);
  const [fileSize, setFileSize] = useState(null);
  const [url, setUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');

  const inputFile = useRef(null);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setIsOptimized(() => {
        //only allow files < 10MB to be uploaded
        return files[0].size < 10000000;
      });

      setFileSize(getFileSize(files[0]));

      //get file type (jpg, png, pdf, mp3)
      setFileName(files[0].name);
      const parts = files[0].name.split('.');
      setFileType(parts[parts.length - 1]);
      setImage(files[0]);
    }
  };

  const handleGenerateLink = async (file) => {
    await handleUpload(file).then((result) => {
      handleSetLink(fileType, fileName);
    });
  };

  const handleSetLink = (type, name) => {
    switch (type) {
      case 'mp3':
        setUrl(
          `https://storage.googleapis.com/bela-develop.appspot.com/audio/${name}`
        );
        break;
      default:
        setUrl(
          `https://storage.googleapis.com/bela-develop.appspot.com/images/${name}`
        );
    }
  };

  const onClick = () => {
    inputFile.current.click();
  };

  return (
    <div className="modal-media-uploader">
      <div className="overlay" />
      <div className="upload-modal-content">
        <h1
          style={{
            fontWeight: 800,
            textAlign: 'left',
            fontSize: '32px',
            marginTop: '0.5rem'
          }}
        >
          Upload Media File
        </h1>
        <p>
          By uploading a media file, we will generate a link for you to copy and
          paste into Rowy.
        </p>
        <div className="dottedBorder">
          <div className="uploadedFileContainer">
            {image ? (
              <React.Fragment>
                {fileType !== 'mp3' && fileType !== 'pdf' && (
                  <div className="mediaPreview">
                    <img src={URL.createObjectURL(image)} alt="Thumb" />
                  </div>
                )}
                <div>
                  <span>Filename: {image.name}</span>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <IconContext.Provider
                  // eslint-disable-next-line react/jsx-no-constructed-context-values
                  value={{ color: 'grey', className: 'fileImage' }}
                >
                  <div>
                    <FaIcons.FaImages />
                  </div>
                </IconContext.Provider>
                <div className="dropBoxText">
                  <span>Drop your media file here, or</span>
                  <span>
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      onChange={handleFileUpload}
                      style={{ display: 'none' }}
                    />
                    <button onClick={onClick} className="browseFile">
                      browse
                    </button>
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <p>These file types are accepted: jpeg, jpg, png, mp3</p>

        <p className="uploadProgress">
          {fileSize && 'Current File Size:'} {fileSize}
          {isOptimized ? '' : 'Please upload a file that is less than 10MB.'}
        </p>
        <div className="generatedLinkContainer">
          <span style={{ fontWeight: 700 }}>Generated Link: </span>
          <p href={url && url}>{url && url}</p>
        </div>
        <div className="btnExitMedia">
          <button
            className="btnCancelMedia"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </button>
          <button
            className="generateLink"
            onClick={(ev) => {
              ev.preventDefault();
              handleGenerateLink(image);
            }}
            disabled={!isOptimized}
          >
            Generate Link
          </button>

          <CloseIcon
            className="admin-close-modal"
            fontSize="50px"
            onClick={() => setOpenModal(false)}
          />
        </div>
      </div>
    </div>
  );
}
