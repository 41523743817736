/* eslint-disable camelcase */
//import { BrowserHistory } from "history";
import { useContext, useState, useLayoutEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

// custom hook code from: https://thewebdev.info/2022/04/02/how-to-intercept-and-handle-browsers-back-button-in-react-router-v6/
export default function usePathname() {
  const [state, setState] = useState(window.location.pathname);

  const navigation = useContext(UNSAFE_NavigationContext).navigator;

  useLayoutEffect(() => {
    if (navigation) {
      navigation.listen((locationListener) =>
        setState(locationListener.location.pathname)
      );
    }
  }, [navigation]);

  return state;
}
