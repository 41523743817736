import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as MegaDev } from './md-blue.svg';
import { ReactComponent as BelaLogoSignIn } from './belaLogin.svg';
import { ReactComponent as BelaLogoStandard } from './belaStandard.svg';

export default {
  Facebook,
  Linkedin,
  Twitter,
  MegaDev,
  BelaLogoSignIn,
  BelaLogoStandard
};
