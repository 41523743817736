import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ExitToApp } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import SidebarData from './AdminSideBarData';
import BelaLogo from '../../assets/belaLogo.png';

export default function AdminHamburger() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    //changes the function state according to the value of open
    setDrawerOpen(open);
  };

  return (
    <div className="AdminHamburger">
      <Button onClick={toggleDrawer(true)} sx={{ margin: '1rem' }}>
        <MenuIcon sx={{ color: '#000000' }} fontSize="large" />
      </Button>
      <Drawer
        className="AdminNavbarHamburger"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div>
            <img src={BelaLogo} className="BelaLogo" alt="bela logo" />
            <nav className="admin-hamburger-menu-items">
              <div>
                {SidebarData.map((item, index) => (
                  <NavLink
                    key={index}
                    id="user-navLink"
                    className={item.cName}
                    to={item.path}
                    end
                    style={{
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      fontSize: 16,
                      padding: '1rem',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </NavLink>
                ))}
              </div>
            </nav>
            <div className="adminCardLogoutContainer">
              <Link href="/" underline="none">
                <div className="divLogOut">
                  <div className="btnLogout">Logout</div>
                  <div className="listIcon">
                    <ExitToApp />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
