import { CProgress, CProgressBar } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import React from 'react';
import './ProgressBar.css';
/**
 * Time runs out
 * set time out flag to true
 * conditionall render times up Modal on the page
 */
export function ProgressBar(props) {
  return (
    <div className="progressBarContainer">
      <div className="questionType">{props.testType}</div>
      <CProgress className="progress">
        <CProgressBar
          color={props.color}
          value={parseInt(props.progressValue)}
        />
      </CProgress>
      <div className="timer">
        {props.minutes < 10 ? `0${props.minutes}` : props.minutes}:
        {props.seconds < 10 ? `0${props.seconds}` : props.seconds}
      </div>
    </div>
  );
}
