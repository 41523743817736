import React from 'react';
import offersImage from '../../assets/offersForYouImage.png';
import './OffersForYou.css';
import { useTheme } from '../../context/ThemeProvider';

export default function OffersForYour() {
  const theme = useTheme();
  return (
    <div className="offersContainer">
      <div
        className="offersTitle offersRow"
        style={{
          fontFamily: theme.typography.h2.fontFamily,
          fontWeight: 800,
          fontSize: theme.typography.h2.fontSize,
          color: '#000000'
        }}
      >
        Offers for you
      </div>
      <div className="offersContent offersRow">
        <img className="offersImage" src={offersImage} alt="offersBackgroun" />
        <div className="offersDescriptionContent">
          <div className="offersDescription">
            <strong>SPEAKHABLA</strong> offers business English classes,
            courses, and workshops.
          </div>
          <button className="offersButton">Browse services</button>
        </div>
      </div>
    </div>
  );
}
