import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../assets/index';
import { useTheme } from '../../context/ThemeProvider';

import './Header.css';

export default function Header(props) {
  const { backgroundColor, isSignInPage, headerClass } = props;
  const theme = useTheme();

  return (
    <div className={headerClass} style={{ backgroundColor }}>
      {isSignInPage ? (
        <a href="/" className="belaLogo">
          <Icon.BelaLogoSignIn />
        </a>
      ) : (
        <React.Fragment>
          <a href="/" className="belaLogo">
            <Icon.BelaLogoStandard />
          </a>

          <div className="signInLink" style={{ color: theme.colors.black }}>
            Have an Account?
            <button className="signInLinkButton" onClick={() => {}}>
              <Link to="/">Sign In</Link>
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
