import './LoaderPage.css';
import { FadeLoader } from 'react-spinners';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { useTheme } from '../../../context/ThemeProvider';

import VolumeCheckPage from '../VolumeCheckPage';
import TestPartIntro from '../TestPartIntro/TestPartIntro';
import YourResultsPage from '../YourResultsPage/YourResultsPage';

export default function LoaderPage({ title, step }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  const { state } = useLocation();

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);

    // clean up function
    return () => setLoading(false);
  }, []);

  return (
    <div>
      <div className="LoaderPage">
        {loading === true && (
          <div className="loaderContainer">
            {title !== undefined || state ? (
              <h1> {title || state}</h1>
            ) : (
              <h1> Pending Title </h1>
            )}
            <Box sx={{ display: 'flex' }}>
              <FadeLoader color={theme.colors.main} />
            </Box>
          </div>
        )}
        {!loading && step === 'volumeCheck' ? (
          <VolumeCheckPage />
        ) : !loading && step === 'test' ? (
          <TestPartIntro />
        ) : (
          !loading && <YourResultsPage results={state && state} />
        )}
      </div>
    </div>
  );
}
