import React from 'react';
import * as ImIcons from 'react-icons/im';
import * as MdIcons from 'react-icons/md';
import * as BsIcons from 'react-icons/bs';
import * as IoIcons from 'react-icons/io';

const AdminSidebarData = [
  {
    title: 'Manage Assessment',
    path: '/admin',
    icon: <BsIcons.BsFillFileEarmarkTextFill />,
    cName: 'active-link'
  }
];

export default AdminSidebarData;
