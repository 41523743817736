import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../PageTitle/PageTitle';

export default function ProfileSettings() {
  return (
    <div>
      <Helmet>
        <title>BELA - Profile Settings</title>
      </Helmet>
      <PageTitle text="Profile Settings" />
    </div>
  );
}
