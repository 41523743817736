import React from 'react';
import Modal from '@mui/material/Modal';
import './ExitWarningPop.css';
import { Container, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import SmallButton from '../SmallButton/SmallButton';

import warningIcon from '../../assets/warning-svgrepo-com.png';

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 583,
  height: 313,
  bgcolor: '#FFFFFF',
  border: '2px solid #707070',
  borderRadius: '24px',
  p: 4,
  color: '#000000'
};

export default function ExitWarningPop({ toggleModal, goHome }) {
  return (
    <div className="ExitPopupOverlay">
      <Container sx={style}>
        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={toggleModal}>
          <CloseIcon />
        </IconButton>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1rem'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '1rem'
            }}
          >
            <img alt="warning-icon" src={warningIcon} />
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginLeft: '1.5rem',
                whiteSpace: 'pre-wrap',
                textAlign: 'start'
              }}
            >
              {'Are you sure you want to\nexit the assessment?'}
            </span>
          </div>
          <span
            style={{
              marginBottom: '1rem',
              whiteSpace: 'pre-wrap'
            }}
          >
            All of the progress that you have made in the assessment will not be
            saved. If you wish to retake the assessment at a later time, you
            will need to start over from the very beginning.
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-end',
            alignItems: 'center'
          }}
        >
          <button
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              textDecoration: 'underline',
              marginRight: '2rem',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none'
            }}
            onClick={toggleModal}
            tabIndex="0"
          >
            Cancel
          </button>
          <SmallButton text="Exit Assessment" onClick={goHome} />
        </div>
      </Container>
    </div>
  );
}
