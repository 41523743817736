import React, { useEffect, useState } from 'react';
import { send } from 'emailjs-com';
import LevelComponentTest from '../../LevelComponentTest/LevelComponentTest';
import OffersForYouComponent from '../../OffersForYou/OffersForYou';
import RightSideBarTest from '../../RightSideBarTest/RightSideBarTest';
import './YourResultsPage.css';
import { useTestContext } from '../../../context/TestProvider';
import { useLoggedInUser } from '../../../context/UserProvider';

export default function YourResultsPage() {
  const [test, setTest] = useState(null);
  const { currentTestProgress } =
    useTestContext();
  const { loggedInUser } = useLoggedInUser();
  const [toSend, setToSend] = useState({
    from_name: 'SpeakHabla',
    message: '',
    reply_to: loggedInUser.email,
  });
  function onSubmit() {
    send(
      'service_5trtymh',
      'template_5h15poj',
      toSend,
      'JsrV65za8Y5WY3Qqe'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setToSend({ from_name: '', message: '', reply_to: "" });
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  }
  useEffect(() => {
    //creating a new test, if not assigned by the manager
    // grab test data from context and create a test object 
    setTest(currentTestProgress);
  }, [currentTestProgress]);
  useEffect(() => {
    setToSend({
      from_name: 'SpeakHabla',
      message: `You have completed a BELA. Your overall Score is ${currentTestProgress.overallScore.description}, sign into bela-test.com to see your certificate.`,
      reply_to: loggedInUser.email,
    });
    onSubmit();
  }, [test]);

  return (
    test && (
      <div className="yourResultsPage">
        <div className="yourResultsLeftColumn">
          <div className="yourResultsTitle">
            <h1>Your BELA Results</h1>
          </div>
          <div className="leftComponents" style={{ height: '38rem' }}>
            <div className="yourResultsleftColumnTopHalf">
              <LevelComponentTest test={test} />
            </div>
            <div className="yourResultsleftColumnBottomHalf">
              <OffersForYouComponent />
            </div>
          </div>
        </div>
        <div className="yourResultsRightColumn">
          <div className="getMyCertComponent">
            <RightSideBarTest test={test} />
          </div>
        </div>
      </div>
    )
  );
}
