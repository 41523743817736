import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ExitToApp } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SidebarData from './SidebarData';
import BelaLogo from '../../assets/belaLogo.png';
import NavHamburger from './NavHamburger';
import './Navbar.css';

function Navbar() {
  const location = useLocation();

  return (
    <React.Fragment>
      <NavHamburger />
      <div className="Navbar">
        <div className="content-wrapper">
          <img src={BelaLogo} className="BelaLogo" alt="bela logo" />
          <nav className="nav-menu-items" style={{ margin: 0, width: '100%' }}>
            <div className="navLink-wrapper">
              {SidebarData.map((item, index) => (
                <NavLink
                  key={index}
                  id="user-navLink"
                  className={item.cName}
                  to={item.path}
                  end
                  style={{
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontSize: 16,
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {item.icon}
                  <span>{item.title}</span>
                </NavLink>
              ))}
            </div>
          </nav>
          <div className="cardLogoutContainer">
            <Card
              className="belaCard"
              sx={{
                borderRadius: 5,
                width: 220,
                height: 281
              }}
            >
              <CardContent>
                <div
                  variant="contained"
                  style={{
                    backgroundColor: '#104d64',
                    borderRadius: '6px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    marginBottom: 5,
                    width: 'fit-content',
                    padding: '5px 12px',
                    color: '#ffffff'
                  }}
                >
                  Offers for you
                </div>
                <Typography className="belaCardText" variant="body2">
                  <strong>SPEAKHABLA </strong>
                  offers business English classes, courses, and workshops.
                </Typography>
              </CardContent>
              <CardActions>
                <Link
                  href="https://speakhabla.com/"
                  variant="outlined"
                  className="browseServicesBtn"
                  target="_blank"
                  sx={{
                    borderColor: '#A9C8D5',
                    color: '#000',
                    borderRadius: 6,
                    padding: 1,
                    backgroundColor: '#DDEDF3',
                    textTransform: 'Capitalize',
                    fontWeight: 'bold',
                    alignSelf: 'center',
                    alignContent: 'center',
                    justifySelf: 'center',
                    marginTop: 5,
                    marginLeft: 2,
                    marginRight: 2,
                    width: '100%'
                  }}
                >
                  Browse services
                </Link>
              </CardActions>
            </Card>
            <Link href="/" underline="none">
              <div className="divLogOut">
                <div className="btnLogout">Logout</div>
                <div className="listIcon">
                  <ExitToApp />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Navbar;
