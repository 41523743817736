import React, { createContext, useContext, useMemo, useState } from 'react';

import { useSessionStorageHook } from '../components/hooks/useSessionStorageHook';

const UserContext = createContext();

function UserProvider(props) {
  const [loggedInUser, setLoggedInUser] = useSessionStorageHook(
    'loggedInUser',
    null
  );
  const [bestScore, setBestScore] = useSessionStorageHook('bestScore', null);
  const [testsTaken, setTestsTaken] = useSessionStorageHook('testsTaken', null);
  const [latestScore, setLatestScore] = useSessionStorageHook(
    'latestScore',
    null
  );
  const [certModal, setCertModal] = useState(false);

  const toggleCertModal = () => {
    setCertModal(!certModal);
  };

  const values = useMemo(
    () => ({
      loggedInUser,
      setLoggedInUser,
      testsTaken,
      setTestsTaken,
      bestScore,
      setBestScore,
      latestScore,
      certModal,
      toggleCertModal,
      setLatestScore
    }),
    [
      loggedInUser,
      setLoggedInUser,
      testsTaken,
      setTestsTaken,
      bestScore,
      setBestScore,
      latestScore,
      certModal,
      toggleCertModal,
      setLatestScore
    ]
  );
  return <UserContext.Provider value={values} {...props} />;
}

function useLoggedInUser() {
  const context = useContext(UserContext);
  if (!context) throw new Error('Not inside the Provider');
  return context;
}

export { UserProvider, useLoggedInUser };
