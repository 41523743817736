import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  sendEmailVerification,
  applyActionCode,
  onAuthStateChanged
} from 'firebase/auth';
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadString,
  uploadBytes
} from 'firebase/storage';
import {
  collection,
  where,
  serverTimestamp,
  getDoc,
  getDocs,
  addDoc,
  query,
  updateDoc,
  arrayUnion,
  getFirestore,
  doc,
  limit,
  writeBatch
} from 'firebase/firestore';
import filesize from 'filesize';
import Compressor from 'compressorjs';
// Initialize Firebase
initializeApp({
  apiKey: 'AIzaSyCKnDki9Anrl6K3bOhQUcYedKagvzAYBQ8',
  authDomain: 'bela-develop.firebaseapp.com',
  projectId: 'bela-develop',
  storageBucket: 'bela-develop.appspot.com',
  messagingSenderId: '43324448497',
  appId: '1:43324448497:web:4a1762e77dbacbd5115350',
  measurementId: 'G-J2JV5G2PY4'
});

// firebase instances
const db = getFirestore();
const auth = getAuth();
const storage = getStorage();
const functions = getFunctions();

// firestore users collections reference
const userCollectionRef = collection(db, 'users');

// reusable function to add data to the databaswe.
// accepts two arguments, the route: which is the collection
// and the object to add.
export async function addDataToDb(route, objToAdd) {
  const data = await addDoc(collection(db, route), objToAdd);
  return data;
}

// function to generate an random firestore ID
export function randomDocIdGen() {
  const docID = doc(collection(db, 'genIds')).id;
  return docID;
}

//this function allows us to get all the sets available for a specific testType and level
async function getAllSets(collectionName, testType, level) {
  const querySnapshot = await getDocs(
    collection(db, collectionName, testType, level)
  );
  const sets = [];
  querySnapshot.forEach((item) => {
    sets.push(item.id);
  });

  return sets;
}

// Reusable function to query docs by refs from DB.
// this function will query any documents by its reference object
// for this one, we are taking the array of references, since we are querying the tests references.
export async function queryDocsByRefs(collectToQuery, arrayOfRefs) {
  return new Promise((resolve, reject) => {
    const refsId = arrayOfRefs.map((referenceObj) => referenceObj.id);

    const arrayOfQueries = refsId.map((docId) =>
      query(collection(db, collectToQuery), where('__name__', '==', docId))
    );

    try {
      const docsArray = arrayOfQueries.map((q) => getDocs(q));
      Promise.all(docsArray).then((docs) => {
        const arrayOfDocuments = [];
        docs.forEach((item) => {
          item.forEach((i) => {
            const obj = {};
            obj[i.id] = i.data();
            arrayOfDocuments.push(obj);
          });
        });
        resolve(arrayOfDocuments);
      });
    } catch (error) {
      reject(error);
    }
  });
}

// Reusable function to get Data from DB;
export const getDataFromDB = async (route) => {
  const querySnapshot = await getDocs(collection(db, route));
  // eslint-disable-next-line no-shadow
  const dataArray = [];
  querySnapshot.forEach((document) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, ' => ', doc.data());
    const docObj = {
      id: document.id,
      data: document.data()
    };
    dataArray.push(docObj);
  });
  return dataArray;
};

//querying questions by level from the database
export async function getQuestionsByLevel(collectionName, level, qtyQuestions) {
  // if collection is grammar, querying differently
  const q = query(
    collection(db, collectionName),
    where('level', '==', level),
    limit(qtyQuestions)
  );

  const questions = [];
  const questionDocs = await getDocs(q);
  questionDocs.forEach((item) => {
    const questionObj = {};
    questionObj[item.id] = item.data();
    questions.push(questionObj);
  });

  return questions;
}

// This function takes the grammar questions collection to query and an array of objects, with levels and questions per level
export async function queryQuestions(collectionName, arrayOfLevels) {
  return new Promise((resolve, reject) => {
    const random = Math.floor(Math.random() * 100);
    // building a query for each level
    const queries = arrayOfLevels.map((item) =>
      query(
        collection(db, collectionName),
        where('level', '==', item.level),
        where('randomId', '>=', random),
        limit(item.numberOfQuestions)
      )
    );
    try {
      const docsArray = queries.map((q) => getDocs(q));
      Promise.all(docsArray).then((docs) => {
        const questions = [];
        docs.forEach((item) => {
          item.forEach((i) => {
            const obj = {};
            obj[i.id] = i.data();
            questions.push(obj);
          });
        });
        resolve(questions);
      });
    } catch (error) {
      reject(error);
    }
  });
}

// function to query questions by level and sets - if we have enough questions per set, we will get all the questions,
// otherwise we will get the max number of questions available per each random set.
// if we don't, we will query the max number of available questions per set.
export async function queryQuestionsBySets(collectionName, arrayOfLevels) {
  const maxSetsNumber = 5;
  // generating a random number, we will fetch random sets based on this number.
  const randomNumber = Math.round(Math.random() * maxSetsNumber);

  return new Promise((resolve, reject) => {
    const queries = arrayOfLevels.map((item) =>
      query(
        collection(db, collectionName),
        where('level', '==', item.level),
        where('set', '==', randomNumber),
        limit(item.numberOfQuestions)
      )
    );
    try {
      const docsArray = queries.map((q) => getDocs(q));
      Promise.all(docsArray).then((docs) => {
        const questions = [];
        docs.forEach((item) => {
          item.forEach((i) => {
            const obj = {};
            obj[i.id] = i.data();
            questions.push(obj);
          });
        });
        return resolve(questions);
      });
    } catch (error) {
      reject(error);
    }
  });
}

// this function will query the user document by it's ID and will update the tests taken array
export async function queryUserByIdAndUpdate(userId, docRefToAdd) {
  const docRef = doc(collection(db, 'users'), userId);

  // adding the new test on the DB object
  await updateDoc(docRef, {
    'tests.tests_taken': arrayUnion(docRefToAdd),
    timestamp: serverTimestamp()
  });

  const updatedUserDoc = await getDoc(docRef);
  return updatedUserDoc.data();
}

// this function will querythe test document by its ID and will update the document
export async function queryTestByIdAndUpdate(testId, UpdatedTest) {
  const docRef = doc(collection(db, 'test'), testId);
  console.log(docRef);
  await updateDoc(docRef, UpdatedTest);
  const updatedUserDoc = await getDoc(docRef);
  return updatedUserDoc.data();
}

// this function will validate the provided company code on the data base
// this returns a promise, if that code is valid, then the that company code
// is added to the userProvider, otherwise this promise will reject
export async function validateCompanyCode(userInputCompanyCode, newEmployeeId) {
  try {
    const q = query(
      collection(db, 'users'),
      where('companyCode', '==', userInputCompanyCode)
    );

    // getting the company doc
    const companyDocs = await getDocs(q);

    // if we have a matching company code
    if (!companyDocs.empty) {
      let companyId;
      let employeesArray;
      companyDocs.forEach((companyDoc) => {
        const companyData = companyDoc.data();
        companyId = companyDoc.id;
        employeesArray = companyData.employees;
      });

      // company reference document
      const companyRef = doc(collection(db, 'users'), companyId);

      const newEmployeeRef = doc(collection(db, 'users'), newEmployeeId);
      // insert the new employee ref into the company profile
      await updateDoc(companyRef, {
        employees: arrayUnion(...employeesArray, newEmployeeRef),
        timestamp: serverTimestamp()
      });

      //after adding the data to the company profile, we need to update the user profile
      const newEmployeeDoc = await getDoc(newEmployeeRef);
      if (newEmployeeDoc.exists()) {
        // adding the company ref into the user profile
        await updateDoc(newEmployeeRef, {
          company: companyRef,
          timestamp: serverTimestamp()
        });
      }
      // returning true if everything is fine
      return true;
    }
  } catch (error) {
    console.error(error);
    //returning false if we have any error on the validation
    return false;
  }
}

export async function sendDataInBatch(collect, dataToAdd) {
  // Get a new write batch
  const batch = writeBatch(db);

  // Set the value of the document
  const collectionRef = collection(db, collect);
  batch.set(collectionRef, dataToAdd);

  // Commit the batch
  await batch.commit();
}

// Authentication Region
const actionCodeSettings = {
  // change to our actual url we want to redirect our users, once we have one.
  url: 'https://bela-test.com',
  handleCodeInApp: true
  // dynamicLinkDomain: 'example.page.link',
};

// this function sends the email for email verification for the current user
export default function sendEmailVerificationLink(user = auth.currentUser) {
  return new Promise((resolve, reject) => {
    sendEmailVerification(user, actionCodeSettings)
      .then(() => {
        // checking if the email is verified
        const interval = setInterval(() => {
          user.reload();
          const isEmailVerified = user.emailVerified;
          if (isEmailVerified) {
            clearInterval(interval);
            resolve(isEmailVerified);
          }
        }, 5000);
      })
      .catch((err) => {
        console.error(err.message);
        reject(err);
      });
  });
}

// This function takes the employees data array and returns a modified array of employees
// with each object containing the tests taken for each employee.
export async function queryTestsByEmployees(employeesDataArray) {
  return new Promise((resolve, reject) => {
    try {
      const usersObjArr = Object.values(...employeesDataArray);
      // for each employee we have we want the tests already taken information
      const testsTakenRefArray = usersObjArr.map(
        (employee) => employee.tests.tests_taken && employee.tests.tests_taken
      );

      // now we need to get each reference inside the test taken array
      const newTestsPromise = (async () => {
        return new Promise((success, failure) => {
          try {
            let docs;
            testsTakenRefArray.forEach(async (testsTaken) => {
              // const newTestsTakenArray = [];
              // if the employee has already taken a test, query the db to get tests information
              await queryDocsByRefs('test', testsTaken).then((queryDocs) => {
                docs = queryDocs.map((test) => {
                  const keys = Object.keys(test);
                  return test[keys];
                });
              });

              success(docs);
            });
          } catch (error) {
            console.error(error.message);
            failure(error.message);
          }
        });
      })();

      newTestsPromise.then((modifiedTestsArray) => {
        // now we can create an object for each user with the data
        const employeesObjArr = usersObjArr.map((user) => {
          return {
            first_name: user.name.first,
            last_name: user.name.last,
            tests_taken: modifiedTestsArray
          };
        });
        //resolving the first promise
        resolve(employeesObjArr);
      });
    } catch (error) {
      console.warn(error.message);
      reject(error);
    }
  });
}

// Reusable function to get Data from DB;
// takes an array of references
export async function queryDocByRef(collectToQuery, arrayOfReferences) {
  const employeesId = arrayOfReferences.map((employeeDoc) => employeeDoc.id);

  // querying each employee document on the database
  const arrayOfQueries = employeesId.map((id) =>
    query(collection(db, collectToQuery), where('__name__', '==', id))
  );

  const targetDoc = await getDocs(arrayOfQueries);

  let questionDoc;
  targetDoc.forEach((document) => {
    questionDoc = document.data();
  });

  return questionDoc;
}
// function that is used to feed the Admin/Manage Assessments Page
// it takes an array of collections (skills)
// and returns an array of objects, with each object being the skill
// (grammar, listening, reading and the total documents on each collection)

export async function countQuestionsDB(arrayOfCollections) {
  const arrayOfData = await arrayOfCollections.map(async (item) => {
    const refs = collection(db, item);
    const docs = await getDocs(refs);

    return {
      skill: item,
      qtyOfQuestions: docs.size
    };
  });

  return Promise.all(arrayOfData).then((data) => data);
}

// sign up authentication function logic
export async function signUpUser(userEmail, userPassword, dataToAdd) {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, userEmail, userPassword)
      .then(async (userCredential) => {
        // add the newly created user to the database
        // sending the verification email after signing up
        if (userCredential != null) {
          await addDataToDb('users', dataToAdd);
          resolve(user);
          // await sendEmailVerificationLink(userCredential.user).then(
          //   async (emailIsConfirmed) => {
          //     console.log(emailIsConfirmed);
          //     // adding the user data to the database
          //     const user = await addDataToDb('users', dataToAdd);
          //     resolve(user);
          //   }
          // );
        }
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
}

// authenticate user function
export function authenticateUser(userEmail, userPassword) {
  const user = signInWithEmailAndPassword(auth, userEmail, userPassword)
    .then(async (userCredential) => {
      // Signed in
      const thisEmail = await userCredential.user.email;
      // querying the database to find the user
      const q = query(userCollectionRef, where('email', '==', thisEmail));
      const userSnapshot = await getDocs(q);
      let userData;
      userSnapshot.forEach((item) => {
        userData = { ...item.data(), id: item.id };
        delete userData.password;
      });
      return userData;
    })
    .catch((error) => {
      //signIn error handling
      console.warn(error.message);
      alert(
        `Your login was not successful, Please try again!: ${error.message}`
      );
    });
  return user;
}

export async function signOutUser() {
  try {
    await signOut(auth);
  } catch (err) {
    //  console.error(err.message);
  }
}

export const currentUser = () =>
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      return user;
    }
    return { error: 'user is signed out' };
  });

export const sendPasswordResetLink = (email) => {
  const passwordResetSettings = {
    url: 'http://localhost:3000/forgotPassword/success',
    handleCodeInApp: true
  };
  sendPasswordResetEmail(auth, email, passwordResetSettings)
    .then(() =>
      // Password reset email sent!
      // ..
      ({ status: 'email sent' })
    )
    .catch(
      (error) => ({ error: error.message })
      // ..
    );
};

export const changeUserPassword = (oobCode, newPassword) =>
  confirmPasswordReset(auth, oobCode, newPassword);

export function verifyEmailConfirm(oobCode) {
  applyActionCode(auth, oobCode);
}

export const handleUpload = (file) => {
  return new Promise((resolve, reject) => {
    try {
      //check file size
      getFileSize(file);
      //check file type
      if (file.type.match('image.*')) {
        //compress image
        console.log('compressing image');
        resolve(imageCompression(file));
      } else {
        //upload file(audio)
        console.log('uploading audio');
        resolve(uploadAudio(file));
      }
    } catch (err) {
      //something went wrong, rejecting the promise
      reject(err.message);
    }
  });
};

const imageCompression = (image) => {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 0.8,
      success: (compressedImage) => {
        resolve(uploadImage(compressedImage));
      },
      error: (error) => {
        reject(error.message);
      }
    });
  });
};

const uploadImage = async (file) => {
  const storageRef = ref(storage, `images/${file.name}`);
  return new Promise((resolve, reject) => {
    try {
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      });
    } catch (err) {
      reject(err.message);
    }
  });
};

const uploadAudio = async (file) => {
  const storageRef = ref(storage, `audio/${file.name}`);
  return new Promise((resolve, reject) => {
    try {
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      });
    } catch (err) {
      reject(err.message);
    }
  });
};

export const getFileSize = (file) => {
  if (file) {
    return filesize(file.size);
  }
};

export const parseLink = (link) => {
  if (link) {
    return link.split('&token')[0];
  }
};
// function to upload image to storage
export async function uploadBase64ImageToDB(base64Data) {
  const random = (Math.random() + 1).toString(36).substring(7);
  const storageRef = ref(storage, 'images/' + random);
  try {
    const uploadTask = await uploadString(storageRef, base64Data, 'data_url');
    console.log(uploadTask);
    const previewLink = `https://storage.googleapis.com/bela-develop.appspot.com/images/${random}`;
    console.log(previewLink);
    return previewLink;
  } catch (err) {
    console.error(err);
  }
}

// cloud function to encrypt/decrypt questions
export async function encryptData(data) {
  const encryptDataFunction = httpsCallable(functions, 'encryptData');
  const encryptedData = await encryptDataFunction(await data);
  return encryptedData.data;
}

export async function decryptData(data) {
  const decryptDataFunction = httpsCallable(functions, 'decryptData');
  const decryptedData = await decryptDataFunction(await data);
  return decryptedData.data;
}

// this function is used to access each question from session storage and decrypt it.
export async function decryptQuestion(questionNumber, encryptedQuestions) {
  const decryptedData = await decryptData(encryptedQuestions);
  const question = decryptedData[questionNumber];
  return question;
}
export async function getAllTestTakersForCompanyCode(employees) {
  console.log(employees);
  if (employees.length > 0) {
    const employeesData = await queryDocsByRefs('users', employees);
    // now that we have the employees data, we want to get the tests by each employee
    // so we can manipulate the data and feed our components.
    console.log(employeesData);
    const employeesAndTestsData = await queryTestsByEmployees(
      await employeesData
    );

    console.log(employeesAndTestsData);
    // creating a new tests_taken array
    const newArray = [];
    employeesAndTestsData.forEach((employee) => {
      console.log(employee);
      employee.tests_taken.forEach((testObj, index) => {
        const myNewObj = {
          id: index,
          firstName: employee.first_name,
          lastName: employee.last_name,
          cefrLevel: testObj.overallScore.level,
          cefrDescription: testObj.overallScore.description,
          overallScore: testObj.overallScore.score,
          assessmentDate: testObj.date
        };
        newArray.push(myNewObj);
      });
    });

    console.log(newArray);
    return (newArray);
  }
}
export async function getAllTestTakers() {
  const userArray = await getDataFromDB('users');
  const testArray = await getDataFromDB('test');
  const individuals = [];
  //loop through users
  console.log(userArray);
  userArray.map((user) => {
    // Save individual users
    if (user.data.type.text === 'individual') {
      individuals.push(user);
    }
  });
  console.log(individuals);
  const newArray = [];
  let counter = 0;
  individuals.forEach((testTaker) => {
    if ('tests_taken' in testTaker.data.tests) {
      if (testTaker.data.tests.tests_taken.length >= 0) {
        testTaker.data.tests.tests_taken.forEach((testObj) => {
          const testInfoObj = testArray.find((x) => x.id === testObj.id);
          const myNewObj = {
            id: counter,
            firstName: testTaker.data.name.first,
            lastName: testTaker.data.name.last,
            cefrLevel: testInfoObj.data.overallScore.level,
            cefrDescription: testInfoObj.data.overallScore.description,
            overallScore: testInfoObj.data.overallScore.score,
            assessmentDate: testInfoObj.data.date,
            userEmail: testTaker.data.email
          };
          newArray.push(myNewObj);
          counter += 1;
        });
      }
    }
  });
  console.log(newArray);
  return newArray;
}
export async function emailTakenCheck(givenEmail) {
  const q = query(userCollectionRef, where('email', '==', givenEmail));
  const userSnapshot = await getDocs(q);
  if (userSnapshot.docs[0]) {
    console.log("Document data:", userSnapshot.docs);
    return true;
  }
  // doc.data() will be undefined in this case
  console.log("No such document!");
  return false;
}
