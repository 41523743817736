export const PASSWORD_RESET_STEPS = {
  EMAIL: 'email',
  PASSWORD: 'password'
};
export const ROUTES = {
  USERS: 'users',
  TEST: 'test',
  COMPANIES: 'companies'
};

export const QUESTION_TYPES = {
  READING: 'Part 2: Reading',
  LISTENING: 'Part 3: Listening',
  GRAMMAR: 'Part 1: Grammar and Vocabulary'
};

export const TEST_TYPES = {
  BELA: 'Bela Testing'
};

export const CEFR_LEVEL_DESCRIPTION = {
  A0: 'Pre-Beginner',
  A1: 'Beginner ',
  A2: 'Elementary ',
  B1: 'Intermediate ',
  B2: 'Upper Intermediate ',
  C1: 'Advanced '
};

export const DB_TEST_COLLECTIONS = {
  //collection and max questions per level/skill
  grammar: [
    'A1_grammarQuestions',
    'A2_grammarQuestions',
    'B1_grammarQuestions',
    'B2_grammarQuestions',
    'C1_grammarQuestions'
  ]
};

export const TRANSLATED_INSTRUCTIONS = {
  aboutBela: {},
  volumeCheck: {},
  assessmentRules: {}
};

export const COMPANY_CODE_TRANSLATIONS = {
  volumeTitle: '¿Tiene un código de empresa?',
  volumeInstructions: '¿Qué es un código de empresa?',
  verification: 'Verificado',
  yesButton: 'Siguiente'
};

export const ASSESSMENT_RULES_TRANSLATIONS = {
  volumeTitle: 'Reglas de evaluación',
  volumeInstructions: [
    'Esta es una evaluación cronometrada.',
    'No puedes pausar la evaluación.',
    'Si sale de la evaluación, su progreso no se guardará.',
    'Debes estar en un lugar tranquilo.',
    'Debes hacer la evaluación solo.',
    'Recibirá los resultados de su evaluación al final.'
  ],
  yesButton: 'Siguiente'
};

export const VOLUME_CHECK_TRANSLATIONS = {
  volumeTitle: 'Comprobación de volumen',
  volumeInstructions: '¿Puedes oír a la persona que habla?',
  noButton: 'No, no puedo oír',
  yesButton: 'Si'
};

export const ABOUT_BELA_TRANSLATIONS = {
  pageTitle: 'Acerca de BELA',
  pageSubtitle:
    'Está a punto de comenzar la evaluación del nivel de inglés comercial. La evaluación se divide en tres secciones: ',
  rules: ['1. Gramática y vocabulario', '2. Lectura', '3. Escuchando'],
  buttonText: 'Okay'
};
