import React from 'react';
import { Link } from 'react-router-dom';
import './CertificatePop.css';
import CloseIcon from '@mui/icons-material/Close';
import certPrev from '../../assets/certPrev2.png';
import PaymentSuccessful from '../PaymentSuccessful/PaymentSuccessful';

export default function CertificatePop(props) {
  const proceedToCheckout = () => (
    <PaymentSuccessful toggleModal={props.toggleModal} />
  );
  return (
    <div>
      <div className="modal-pop">
        <div className="overlay" />
        <div className="popUp-modal-content">
          <h1
            style={{
              fontWeight: 800,
              fontSize: 32,
              alignSelf: 'flex-start',
              marginBottom: '20px',
              marginTop: 0
            }}
          >
            Your BELA Certificate
          </h1>
          <div className="certificatePreviewContainer">
            <div className="certificateSample">
              <img
                src={certPrev}
                alt="certificate sample preview"
                className="imgPrev"
              />
            </div>
            <div className="certificateInfo">
              <div className="priceTag">
                <span>USD $15.00</span>
              </div>
              <div className="listOptions">
                <ul>
                  <li>Professional certificate</li>
                  <li>Downloadable certificate</li>
                  <li>Share certificate on Linkedin</li>
                  <li>Score breakdown</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="btnExit">
            <Link to="/home" className="btn-cancel" onClick={props.toggleModal}>
              Cancel
            </Link>
            {/* <Link to="/home/payment-successful">
              <button
                className="proceedCheckoutButton"
                onClick={proceedToCheckout}
              >
                Proceed to checkout
              </button>
            </Link> */}
            <a
              className="proceedCheckoutButton"
              href="https://www.fygaro.com/es/pb/7525bb9e-178c-4638-a119-02136c8babda/"
            >
              Proceed to Checkout
            </a>
          </div>
          <CloseIcon
            onClick={props.toggleModal}
            className="close-modal"
            fontSize="large"
          />
        </div>
      </div>
    </div>
  );
}
