import React from 'react';
// import OverallSvg from './assets/8.svg';
import GrammarSVG from '../../assets/GrammarSVG.png';
import ReadingSVG from '../../assets/ReadingSVG.png';
import ListeningSVG from '../../assets/ListeningSVG.png';
import './LevelComponentTest.css';
import { useTheme } from '../../context/ThemeProvider';

export default function LevelComponentTest({ test }) {
  const theme = useTheme();
  return (
    <div
      className="levelComponentTest"
      style={{ backgroundColor: theme.primaryColors.white }}
    >
      <div className="column1">
        <div className="column1TopTitle">Overall Score</div>
        <div
          className="column1Percentage"
          style={{
            fontFamily: theme.typography.h1.fontFamily,
            fontWeight: theme.typography.h1.fontWeight,
            color: '#000000'
          }}
        >
          {test.overallScore.score}%
        </div>
        <div style={{ fontSize: theme.typography.h4.fontSize }}>
          {test.date}
        </div>
      </div>
      <div className="column2">
        <div
          className="column2TopTitle"
          style={{
            fontFamily: theme.typography.h3.fontFamily,
            fontWeight: theme.typography.h4.fontWeight,
            fontSize: theme.typography.h2.fontSize,
            color: '#000000'
          }}
        >
          {test.overallScore.description}
        </div>
        <div className="sectionsRow">
          <div
            className="sectionColumn"
            style={{ backgroundColor: theme.primaryColors.dashboardBackground }}
          >
            <div style={{ color: '#000000' }}>Grammar</div>
            <img src={GrammarSVG} alt="grammar icon" />
            <div>{test.grammarScore.score}%</div>
          </div>
          <div
            className="sectionColumn"
            style={{ backgroundColor: theme.primaryColors.dashboardBackground }}
          >
            <div style={{ color: '#000000' }}>Reading</div>
            <img src={ReadingSVG} alt="grammar icon" />
            <div>{test.readingScore.score}%</div>
          </div>
          <div
            className="sectionColumn"
            style={{ backgroundColor: theme.primaryColors.dashboardBackground }}
          >
            <div style={{ color: '#000000' }}>Listening</div>
            <img src={ListeningSVG} alt="grammar icon" />
            <div>{test.listeningScore.score}%</div>
          </div>
        </div>
      </div>
    </div>
  );
}
