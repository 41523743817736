import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import certPrev from '../../assets/certPrev.png';
import CertificatePop from '../CertificatePop/CertificatePop';

import './RightSideBarTest.css';

export default function RightSideBarTest({ test }) {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  return (
    <React.Fragment>
      <div className="rightSideBarTestContainer">
        <div className="rightSideBarTest">
          <div className="cardBarTest">
            <Card className="certCardTest" sx={{ borderRadius: 5 }}>
              <CardContent>
                <div className="cardContentTest">
                  <p>Great work!</p>
                  <p className="textMid">You&apos;ve unlocked the</p>
                  <span style={{ fontWeight: 800, fontSize: '18px' }}>
                    CEFR {test.overallScore.level}
                  </span>
                </div>
              </CardContent>
            </Card>
            <div className="sideBarTestContents">
              <div>
                <p className="textEndTest">
                  Your highest overall score from the BELA assessment will
                  populate into your purchased certificate.
                </p>
              </div>
              <button className="btnGetTest" onClick={toggleModal}>
                Get my certificate
              </button>
            </div>
          </div>
        </div>
      </div>
      {modal && <CertificatePop toggleModal={toggleModal} />}
    </React.Fragment>
  );
}
