import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '../../context/ThemeProvider';
import './ConfirmRegistration.css';
import confirmImg from '../../assets/confirmEmail.png';

import sendEmailVerificationLink from '../../utils/firebase-utils';

export default function ConfirmRegistration() {
  const theme = useTheme();

  const location = useLocation();

  function resendVerification() {
    // Resend verification link
    sendEmailVerificationLink();
  }

  return (
    <div
      className="ConfirmRegistration"
      style={{ backgroundColor: theme.colors.dashboardBackground }}
    >
      <Helmet>
        <title>BELA - Sign Up</title>
      </Helmet>
      <Box style={theme.boxContainer} boxShadow={theme.shadows.main}>
        <h1>Almost done!</h1>
        <p>
          We just sent an email to
          <span style={{ fontWeight: 700 }}>
            &nbsp;
            {location.state && location.state}
            &nbsp;
          </span>
          to verify your email address. Please click the link in that email to
          finish signing up.
        </p>
        <img
          style={{ width: '42%', height: '40%', padding: '2rem' }}
          src={confirmImg}
          alt="registration email"
        />
        <p style={{ fontSize: '18px' }}>
          Don&apos;t see the email?
          <button className="resendButton" onClick={resendVerification}>
            Resend
          </button>
        </p>
      </Box>
    </div>
  );
}
