import React, { useState, useEffect } from 'react';
import './TimesUpModal.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LoaderPage from '../Pages/LoaderPage/LoaderPage';

export default function TimesUpModal(props) {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Modal open={open}>
        <Box
          className="timesUpContent"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFF',
            borderRadius: '30px'
          }}
        >
          <div className="timesUpText">
            <h1>
              <AccessTimeIcon style={{ marginRight: 20 }} />
              Your time is up!
            </h1>
            <p>
              Your progress for this section of the assessment was automatically
              saved and submitted.
            </p>
          </div>
          <button className="timesUpButton" onClick={props.handleTimesUp}>
            {' '}
            Okay{' '}
          </button>
        </Box>
      </Modal>
      {/* {!open && (
        <LoaderPage title="Loading the next section of the BELA Assessment" />
      )} */}
    </div>
  );
}
