import React, { createContext, useContext } from 'react';

const ThemeContext = createContext();

function ThemeProvider(props) {
  return (
    <ThemeContext.Provider value={props.theme}>
      {props.children}
    </ThemeContext.Provider>
  );
}

function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) throw new Error('Not inside the Provider');
  return context;
}

export { ThemeProvider, useTheme };
