import React, { useEffect, useState } from 'react';
import './PaymentSuccessful.css';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { useLoggedInUser } from '../../context/UserProvider';
import CertificateHandling from '../CertificateHandling/CertificateHandling';

export default function PaymentSuccessful(props) {
  const navigate = useNavigate();
  const { certModal, toggleCertModal } = useLoggedInUser();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      navigate('/home');
      toggleCertModal();
    }, 4000);
  }, []);

  return (
    <div className="PaymentSuccessful">
      {loading === true && (
        <Box component="div" className="paymentSuccessfulItems">
          <CheckCircleIcon color="success" className="checkIconPayment" />
          <h1 style={{ fontWeight: 800 }}>Payment Successful</h1>
          <p>
            Redirecting you to the &apos;Home&apos; page to view your
            certificate.
          </p>
        </Box>
      )}
    </div>
  );
}
