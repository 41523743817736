import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import './PasswordResetSuccess.css';
import { useTheme } from '../../../context/ThemeProvider';
import LargeButton from '../../LargeButton/LargeButton';

export default function PasswordResetSuccess() {
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <Box
      component="form"
      className="passwordResetContainer"
      onSubmit={(ev) => {
        ev.preventDefault();
        navigate('/');
      }}
    >
      <Helmet>
        <title>BELA - Forgot Password</title>
      </Helmet>
      <CheckCircleIcon color="success" className="checkIcon" />
      <h1>Password reset was successful!</h1>
      <p className="subtitle">
        Please use your new password to sign in to BELA.
      </p>
      <LargeButton text="Back to Sign in" />
    </Box>
  );
}
