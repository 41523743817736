import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import ReactDOM from 'react-dom';

import './index.css';

import App from './components/App/App';

import theme from './theme';

import { ThemeProvider } from './context/ThemeProvider';
import { UserProvider } from './context/UserProvider';
import { TestProvider } from './context/TestProvider';

ReactDOM.render(
  <HelmetProvider>
    <ThemeProvider theme={theme}>
      <TestProvider>
        <UserProvider>
          <React.StrictMode>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </React.StrictMode>
        </UserProvider>
      </TestProvider>
    </ThemeProvider>
  </HelmetProvider>,
  document.getElementById('root')
);
