import React from 'react';
import './OverviewUsers.css';
import groupPeople from '../../assets/groupPeople.svg';

export default function OverviewUsers({ numberOfUsers }) {
  return (
    <div className="OverviewUsers">
      <h2 className="overviewTitle">Overview</h2>
      <div className="overviewContainer">
        <div className="overviewIconContainer">
          <img
            src={groupPeople}
            alt="Group of users icon"
            style={{ width: '40%' }}
          />
        </div>

        <div className="overviewDescription">
          <span style={{ fontSize: '24px', fontWeight: '700' }}>
            {numberOfUsers}
          </span>
          <p>Total affiliated users</p>
        </div>
      </div>
    </div>
  );
}
