import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useSessionStorageHook } from '../components/hooks/useSessionStorageHook';
import { QUESTION_TYPES, CEFR_LEVEL_DESCRIPTION } from '../utils/constants';
import {
  encryptData,
  queryQuestions,
  queryQuestionsBySets
} from '../utils/firebase-utils';

const Context = createContext();

export default function TestProvider(props) {
  const [currentTestId, setCurrentTestId] = useState(null);
  const [currentTestProgress, setCurrentTestProgress] = useState(null);
  const [testType, setTestType] = useSessionStorageHook(
    'testType',
    QUESTION_TYPES.GRAMMAR
  );
  const [overallScore, setOverallScore] = useState(0);
  const [grammarScore, setGrammarScore] = useState(0);
  const [readingScore, setReadingScore] = useState(0);
  const [listeningScore, setListeningScore] = useState(0);
  const [userLevel, setUserLevel] = useState(null);

  //state variable to control when to fetch a new set of questions
  const [fetchNewSet, setFetchNewSet] = useState(false);

  //setting the maximum questions per test once each test starts
  const [grammarQuestionsQty, setGrammarQuestionsQty] = useSessionStorageHook(
    'grammarQuestionsQty',
    0
  );
  const [readingQuestionsQty, setReadingQuestionsQty] = useSessionStorageHook(
    'readingQuestionsQty',
    0
  );

  const [listeningQuestionsQty, setListeningQuestionsQty] =
    useSessionStorageHook('listeningQuestionsQty', 0);

  // progress bar state
  const [progressValue, setProgressValue] = useSessionStorageHook(
    'progressValue',
    0
  );
  const [testTime, setTestTime] = useSessionStorageHook('timer', null);

  // current setOfQuestions
  const [setOfQuestions, setQuestions] = useSessionStorageHook('data', null);
  const { seconds, minutes, start, reset, pause } = useStopwatch({
    autoStart: false
  });

  function calculateScorePerSkill(
    arrayOfCorrectAnswers,
    qtyOfQuestions,
    skill
  ) {
    //pass an array of correct answers and calculate the score per skill
    //returns an object with the calculated score and level per skill
    const rightAnswers = arrayOfCorrectAnswers.filter(
      (obj) => obj.question_type === skill
    ).length;

    const minAndMaxScores = {
      notGraded: {
        minScore: undefined,
        maxScore: undefined
      },
      A1: {
        minScore: undefined,
        maxScore: undefined
      },
      A2: {
        minScore: undefined,
        maxScore: undefined
      },
      B1: {
        minScore: undefined,
        maxScore: undefined
      },
      B2: {
        minScore: undefined,
        maxScore: undefined
      },
      C1: {
        minScore: undefined,
        maxScore: undefined
      }
    };
    if (skill === QUESTION_TYPES.GRAMMAR) {
      minAndMaxScores.notGraded.minScore = 0;
      minAndMaxScores.notGraded.maxScore = 7;
      minAndMaxScores.A1.minScore = 8;
      minAndMaxScores.A1.maxScore = 20;
      minAndMaxScores.A2.minScore = 21;
      minAndMaxScores.A2.maxScore = 38;
      minAndMaxScores.B1.minScore = 39;
      minAndMaxScores.B1.maxScore = 61;
      minAndMaxScores.B2.minScore = 62;
      minAndMaxScores.B2.maxScore = 87;
      minAndMaxScores.C1.minScore = 88;
      minAndMaxScores.C1.maxScore = 100;
    } else if (skill === QUESTION_TYPES.READING) {
      minAndMaxScores.notGraded.minScore = 0;
      minAndMaxScores.notGraded.maxScore = 10;
      minAndMaxScores.A1.minScore = 11;
      minAndMaxScores.A1.maxScore = 22;
      minAndMaxScores.A2.minScore = 23;
      minAndMaxScores.A2.maxScore = 40;
      minAndMaxScores.B1.minScore = 41;
      minAndMaxScores.B1.maxScore = 59;
      minAndMaxScores.B2.minScore = 60;
      minAndMaxScores.B2.maxScore = 77;
      minAndMaxScores.C1.minScore = 78;
      minAndMaxScores.C1.maxScore = 100;
    } else {
      //listening skill
      minAndMaxScores.A1.minScore = 0;
      minAndMaxScores.A1.maxScore = 12;
      minAndMaxScores.A2.minScore = 13;
      minAndMaxScores.A2.maxScore = 36;
      minAndMaxScores.B1.minScore = 37;
      minAndMaxScores.B1.maxScore = 60;
      minAndMaxScores.B2.minScore = 62;
      minAndMaxScores.B2.maxScore = 84;
      minAndMaxScores.C1.minScore = 85;
      minAndMaxScores.C1.maxScore = 100;
    }

    const scorePerSkill = Math.round(rightAnswers * 100) / qtyOfQuestions;

    let level;
    if (scorePerSkill <= minAndMaxScores.notGraded.maxScore) {
      level = 'Level < A1';
    }
    if (
      scorePerSkill >= minAndMaxScores.A1.minScore &&
      scorePerSkill <= minAndMaxScores.A1.maxScore
    ) {
      level = 'Level A1';
    }
    if (
      scorePerSkill >= minAndMaxScores.A2.minScore &&
      scorePerSkill <= minAndMaxScores.A2.maxScore
    ) {
      level = 'Level A2';
    }
    if (
      scorePerSkill >= minAndMaxScores.B1.minScore &&
      scorePerSkill <= minAndMaxScores.B1.maxScore
    ) {
      level = 'Level B1';
    }
    if (
      scorePerSkill >= minAndMaxScores.B2.minScore &&
      scorePerSkill <= minAndMaxScores.B2.maxScore
    ) {
      level = 'Level B2';
    }
    if (scorePerSkill >= minAndMaxScores.C1.minScore && scorePerSkill <= 100) {
      level = 'Level C1';
    }

    return {
      score: Math.round(scorePerSkill),
      level
    };
  }

  function calculateOverallScore(arrayOfCorrectAnswers, totalQuestionsQty) {
    // pass an array of correct answers and calculate the overall score
    // returns an object with the calculated score and the level
    const rightAnswersQty = arrayOfCorrectAnswers.length;

    const minAndMaxScores = {
      notGraded: {
        minScore: 0,
        maxScore: 11
      },
      A1: {
        minScore: 12,
        maxScore: 23
      },
      A2: {
        minScore: 24,
        maxScore: 49
      },
      B1: {
        minScore: 50,
        maxScore: 71
      },
      B2: {
        minScore: 72,
        maxScore: 91
      },
      C1: {
        minScore: 92,
        maxScore: 100
      }
    };

    const calculatedScore = Math.round(
      (rightAnswersQty * 100) / totalQuestionsQty
    );

    let level;
    let description;
    if (calculatedScore <= minAndMaxScores.notGraded.maxScore) {
      level = 'Level < A1';
      description = CEFR_LEVEL_DESCRIPTION.A0;
    }
    if (
      calculatedScore >= minAndMaxScores.A1.minScore &&
      calculatedScore <= minAndMaxScores.A1.maxScore
    ) {
      level = 'Level A1';
      description = CEFR_LEVEL_DESCRIPTION.A1;
    }
    if (
      calculatedScore >= minAndMaxScores.A2.minScore &&
      calculatedScore <= minAndMaxScores.A2.maxScore
    ) {
      level = 'Level A2';
      description = CEFR_LEVEL_DESCRIPTION.A2;
    }
    if (
      calculatedScore >= minAndMaxScores.B1.minScore &&
      calculatedScore <= minAndMaxScores.B1.maxScore
    ) {
      level = 'Level B1';
      description = CEFR_LEVEL_DESCRIPTION.B1;
    }
    if (
      calculatedScore >= minAndMaxScores.B2.minScore &&
      calculatedScore <= minAndMaxScores.B2.maxScore
    ) {
      level = 'Level B2';
      description = CEFR_LEVEL_DESCRIPTION.B2;
    }
    if (
      calculatedScore >= minAndMaxScores.C1.minScore &&
      calculatedScore <= 100
    ) {
      level = 'Level C1';
      description = CEFR_LEVEL_DESCRIPTION.C1;
    }

    return {
      score: Math.round(calculatedScore),
      level,
      description
    };
  }

  // fetching questions once the test starts
  useEffect(() => {
    //if we don't have any questions
    if (fetchNewSet) {
      if (testType === QUESTION_TYPES.GRAMMAR) {
        queryQuestions('grammarQuestions', [
          { level: 'A1', numberOfQuestions: 10 },
          { level: 'A2', numberOfQuestions: 10 },
          { level: 'B1', numberOfQuestions: 10 },
          { level: 'B2', numberOfQuestions: 10 },
          { level: 'C1', numberOfQuestions: 9 }
        ]).then(async (res) => {
          const stringifiedData = JSON.stringify(res);
          const encryptedData = await encryptData(stringifiedData);
          setGrammarQuestionsQty(res.length);
          setQuestions(encryptedData);
        });
      }

      if (testType === QUESTION_TYPES.READING) {
        queryQuestionsBySets('readingQuestions', [
          { level: 'A1', numberOfQuestions: 7 },
          { level: 'A2', numberOfQuestions: 5 },
          { level: 'B1', numberOfQuestions: 5 },
          { level: 'B2', numberOfQuestions: 5 },
          { level: 'C1', numberOfQuestions: 5 }
        ]).then(async (res) => {
          const stringifiedData = JSON.stringify(res);
          const encryptedData = await encryptData(stringifiedData);
          setReadingQuestionsQty(res.length);
          setQuestions(encryptedData);
        });
      }

      if (testType === QUESTION_TYPES.LISTENING) {
        queryQuestionsBySets('listeningQuestions', [
          { level: 'A1', numberOfQuestions: 5 },
          { level: 'A2', numberOfQuestions: 5 },
          { level: 'B1', numberOfQuestions: 5 },
          { level: 'B2', numberOfQuestions: 5 },
          { level: 'C1', numberOfQuestions: 5 }
        ]).then(async (res) => {
          const stringifiedData = JSON.stringify(res);
          const encryptedData = await encryptData(stringifiedData);
          setListeningQuestionsQty(res.length);
          setQuestions(encryptedData);
        });
      }
    }
  }, [testType, fetchNewSet]);

  const values = useMemo(
    () => ({
      testType,
      setTestType,
      overallScore,
      setOverallScore,
      grammarScore,
      setGrammarScore,
      readingScore,
      setReadingScore,
      listeningScore,
      setListeningScore,
      userLevel,
      setUserLevel,
      progressValue,
      setProgressValue,
      grammarQuestionsQty,
      readingQuestionsQty,
      listeningQuestionsQty,
      seconds,
      minutes,
      start,
      reset,
      pause,
      calculateScorePerSkill,
      calculateOverallScore,
      setOfQuestions,
      setQuestions,
      testTime,
      setTestTime,
      fetchNewSet,
      setFetchNewSet,
      currentTestId,
      setCurrentTestId,
      currentTestProgress,
      setCurrentTestProgress
    }),
    [
      testType,
      setTestType,
      overallScore,
      setOverallScore,
      grammarScore,
      setGrammarScore,
      readingScore,
      setReadingScore,
      listeningScore,
      setListeningScore,
      userLevel,
      seconds,
      minutes,
      start,
      reset,
      pause,
      setUserLevel,
      progressValue,
      setProgressValue,
      grammarQuestionsQty,
      readingQuestionsQty,
      listeningQuestionsQty,
      calculateScorePerSkill,
      calculateOverallScore,
      setOfQuestions,
      setQuestions,
      testTime,
      setTestTime,
      fetchNewSet,
      setFetchNewSet,
      currentTestId,
      setCurrentTestId,
      currentTestProgress,
      setCurrentTestProgress
    ]
  );

  return <Context.Provider value={values} {...props} />;
}

function useTestContext() {
  const context = useContext(Context);
  if (!context) throw new Error('Not inside the Provider');
  return context;
}

export { TestProvider, useTestContext };
