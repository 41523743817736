import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../../context/ThemeProvider';
import LargeButton from '../../LargeButton/LargeButton';

import belaLogoConfirm from '../../../assets/belaLogoConfirm.png';

import './VerifyEmailSuccess.css';

export default function VerifyEmailSuccess() {
  const theme = useTheme();

  const navigate = useNavigate();

  function navigateToLogin() {
    navigate('/');
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundColor: '#f7f8fc'
      }}
    >
      <Helmet>
        <title>BELA - Sign Up</title>
      </Helmet>
      <Box component="div" className="emailVerifiedBox">
        <img alt="bela logo" src={belaLogoConfirm} width="50px" />
        <h1 style={{ fontWeight: 'bold' }}>Thanks for signing up! </h1>
        <Box component="div" className="text">
          <p>
            Your registration process is now complete. Please sign in to BELA
            using your account email and password.
          </p>
        </Box>
        <LargeButton
          text="Back to Sign in"
          className="submitButton"
          onClick={() => navigateToLogin()}
        />
      </Box>
    </Box>
  );
}
