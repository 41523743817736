import React, { useState } from 'react';
import './AdminEditToggle.css';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useTheme } from '../../context/ThemeProvider';

export default function AdminEditToggle({ setModal }) {
  const theme = useTheme();
  const [baseElement, setBaseElement] = useState(null);
  const open = !!baseElement;
  const openEditOptions = (event) => {
    setBaseElement(event.currentTarget);
  };
  const handleClose = () => {
    setBaseElement(null); // closes edit toggle
  };
  const openMediaPopup = () => {
    setBaseElement(null);
    setModal(true);
  };
  return (
    <Box className="AdminEditToggle">
      <Button aria-haspopup="true" onClick={openEditOptions}>
        Edit
        <KeyboardArrowDownRoundedIcon fontSize="medium" className="editArrow" />
      </Button>
      <div className="adminEditOptions">
        <Menu anchorEl={baseElement} open={open} onClose={handleClose}>
          <MenuItem onClick={openMediaPopup}>Upload media file</MenuItem>
          <a
            href="https://bela-develop.rowy.app/"
            target="_blank"
            rel="noreferrer"
            style={{ color: theme.colors.black, textDecoration: 'none' }}
          >
            <MenuItem onClick={handleClose}>Edit in Rowy</MenuItem>
          </a>
        </Menu>
      </div>
    </Box>
  );
}
