import React, { useEffect, useRef, useState } from 'react';
//import { useNavigate } from 'react-router-dom';

import {
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import IntroductionContainer from '../IntroductionContainer/IntroductionContainer';
import LanguageToggle from '../LanguageToggle/LanguageToggle';

import companyCodeBar from '../../assets/companyCode.png';
import companyCodeBarEs from '../../assets/companyCode_es.png';
import { COMPANY_CODE_TRANSLATIONS } from '../../utils/constants';
import SubmitButton from '../SubmitButton/SubmitButton';
import ModalComponent from '../ModalComponent/ModalComponent';
import { useLoggedInUser } from '../../context/UserProvider';
import { validateCompanyCode } from '../../utils/firebase-utils';

export default function TestConnectPage() {
  const [language, setLanguage] = useState('ES');

  const [companyCode, setCompanyCode] = useState('DEMOCODE123');

  const [isCodeVerified, setCodeVerified] = useState(null);

  const [hasSubmittedCode, setHasSubmittedCode] = useState(false);

  const page = 'connectCodePage';

  const originalText = {
    volumeTitle: 'Do you have a Company Code?',
    volumeInstructions: 'What is a Company Code?',
    verification: 'Verified',
    yesButton: 'Next'
  };

  const outlinedInputRef = useRef();

  // loggedInUser provider
  const { loggedInUser } = useLoggedInUser();

  // state variables for the modal
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  function navigation() {
    navigate('/testIntro', {
      state: "We're setting up the assessment for you"
    });
  }

  function verifyCompanyCode() {
    validateCompanyCode(companyCode, loggedInUser.id).then((isCodeValid) => {
      setHasSubmittedCode(true);
      // the code is valid
      if (isCodeValid) {
        outlinedInputRef.current.focus();
        setCodeVerified(true);
        console.log('code is verified');
      } else {
        // the code is not valid
        console.log('company code not valid');
        setCodeVerified(false);
        outlinedInputRef.current.focus();
        // removing the outline after 4 seconds
        setTimeout(() => {
          setCodeVerified(null);
        }, 4000);
      }
    });
  }

  return (
    <Container
      className="instructionsContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 981,
        height: '85vh'
      }}
    >
      <div style={{ alignSelf: 'flex-end', marginBottom: '20px' }}>
        <LanguageToggle
          textToTranslate={originalText}
          language={language}
          setLanguage={setLanguage}
        />
      </div>
      <div />
      <IntroductionContainer
        titleText={
          language === 'ES'
            ? originalText.volumeTitle
            : COMPANY_CODE_TRANSLATIONS.volumeTitle
        }
        instructionsText={
          language === 'ES'
            ? originalText.volumeInstructions
            : COMPANY_CODE_TRANSLATIONS.volumeInstructions
        }
        checkBar={language === 'ES' ? companyCodeBar : companyCodeBarEs}
        yesButtonText={
          language === 'ES'
            ? originalText.yesButton
            : COMPANY_CODE_TRANSLATIONS.yesButton
        }
        yesButtonAction={navigation}
        verification={
          language === 'ES'
            ? originalText.verification
            : COMPANY_CODE_TRANSLATIONS.verification
        }
        companyCodeAction={handleOpen}
        step={page}
        isCodeVerified={isCodeVerified}
        hasSubmittedCode={hasSubmittedCode}
      >
        <FormControl
          sx={{
            m: 1,
            width: '391px',
            '& .MuiOutlinedInput-root': {
              justifyContent: 'space-between',
              paddingRight: '0'
            }
          }}
          variant="outlined"
        >
          <InputLabel
            htmlFor="outlined-adornment-companyCode"
            color={isCodeVerified ? 'success' : 'error'}
          >
            {language === 'ES'
              ? 'Company Code (optional)'
              : 'Código de Empresa (opcional)'}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-companyCode"
            color={isCodeVerified ? 'success' : 'error'}
            inputRef={outlinedInputRef}
            sx={{
              borderRadius: '15px',
              fontFamily: 'Nunito',
              height: '57px',
              '& .MuiOutlinedInput-input': {
                width: '70%'
              }
            }}
            type="text"
            value={companyCode}
            onChange={(ev) => setCompanyCode(ev.currentTarget.value)}
            endAdornment={
              <InputAdornment position="end" sx={{ margin: '0', width: '30%' }}>
                <SubmitButton
                  text={language === 'ES' ? 'Submit' : 'Enviar'}
                  onClick={(ev) => verifyCompanyCode(ev)}
                />
              </InputAdornment>
            }
            label={
              language === 'ES'
                ? 'Company Code (optional)'
                : 'Código de Empresa (opcional)'
            }
          />
        </FormControl>
      </IntroductionContainer>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        page="testConnect"
      />
    </Container>
  );
}
