import React from 'react';
import './PageTitle.css';
import { useTheme } from '../../context/ThemeProvider';

export default function PageTitle({ text, className, style }) {
  const theme = useTheme();

  return (
    <h1
      className={className}
      style={{
        fontFamily: theme.typography.h1.fontFamily,
        fontWeight: theme.typography.h1.fontWeight,
        fontSize: theme.typography.h1.fontSize,
        marginTop: 0,
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'flex-start',
        style,
        color: '#000000'
      }}
    >
      {text}
    </h1>
  );
}
