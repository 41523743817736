import React, { useState } from 'react';
import './GetCertificate.css';
import { Button, Container } from '@mui/material';

import { useTheme } from '../../context/ThemeProvider';

import CertificateBanner from '../../assets/MyCertificateBanner-WithoutCEFR.png';

import { useLoggedInUser } from '../../context/UserProvider';
import CertificatePop from '../CertificatePop/CertificatePop';

import CertificateHandling from '../CertificateHandling/CertificateHandling';

export default function GetCertificateComponent() {
  const [modal, setModal] = useState(false);
  const { testsTaken, bestScore, has_purchased_certificate } =
    useLoggedInUser();

  const theme = useTheme();

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  return (
    <Container className="GetCertificate">
      <h2
        style={{
          fontWeight: '800',
          fontSize: theme.typography.h2.fontSize,
          marginTop: 0,
          color: '#000000'
        }}
      >
        My Certificate
      </h2>
      <div
        className="GetCertificateCard"
        style={{
          backgroundImage: `url(${CertificateBanner})`,
          borderRadius: '15px',
          backgroundSize: 'cover'
        }}
      >
        <div style={{ color: theme.colors.white }}>
          <div
            style={{
              color: theme.colors.white,
              fontSize: theme.typography.h3.fontSize,
              marginBottom: '1rem'
            }}
          >
            {bestScore ? 'Great Work!' : ''}
          </div>
          <div
            style={{
              marginBottom: '2rem'
            }}
          >
            <div
              style={{
                fontSize: theme.typography.h3.fontSize
              }}
            >
              {bestScore
                ? "You've Unlocked the"
                : 'No data to display. Please take the assessment.'}
            </div>
            <div
              style={{
                fontWeight: '700',
                fontSize: theme.typography.h3.fontSize
              }}
            >
              {bestScore ? `${bestScore.overallScore.level}` : ''}
            </div>
          </div>
        </div>
        <button
          onClick={toggleModal}
          className="GetCertificateButton"
          disabled={!bestScore}
        >
          Get my certificate
        </button>
      </div>
      {modal ? (
        testsTaken ? (
          <CertificatePop toggleModal={toggleModal} />
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </Container>
  );
}
