import React, { useState, useEffect } from 'react';

import { Container } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import LanguageToggle from '../LanguageToggle/LanguageToggle';

import volumeCheckBar from '../../assets/volumeCheckBar.png';
import volumeCheckBarES from '../../assets/volumeCheckBar_es.png';
import { VOLUME_CHECK_TRANSLATIONS } from '../../utils/constants';
import ModalComponent from '../ModalComponent/ModalComponent';
import IntroductionContainer from '../IntroductionContainer/IntroductionContainer';
import VolumeCheckMedia from '../VolumeCheckMedia/VolumeCheckMedia';

const storage = getStorage();
const storageRef = ref(storage, `audio/audioSample.mp3`);

export default function VolumeCheckPage() {
  const [language, setLanguage] = useState('ES');

  const originalText = {
    volumeTitle: 'Volume Check',
    volumeInstructions: 'Can you hear the music playing?',
    noButton: "No, I can't hear",
    yesButton: 'Yes'
  };

  const page = 'volumePage';

  const [text, setText] = useState({});

  const [translatedText, setTranslatedText] = useState('');

  const [triggerStopSound, setTriggerStopSound] = useState(false);

  // state variables for the modal

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // media for volume page
  const [url, setUrl] = useState('');

  useEffect(() => {
    getDownloadURL(storageRef)
      .then((_url) => {
        setUrl(_url);
      });
  }, []);
  const navigate = useNavigate();

  function navigation() {
    setTriggerStopSound(true);
    navigate('/assessmentRules');
  }

  return (
    <Container
      className="instructionsContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 981,
        height: '85vh'
      }}
    >
      <div style={{ alignSelf: 'flex-end', marginBottom: '20px' }}>
        <LanguageToggle language={language} setLanguage={setLanguage} />
      </div>
      <IntroductionContainer
        titleText={
          language === 'ES'
            ? originalText.volumeTitle
            : VOLUME_CHECK_TRANSLATIONS.volumeTitle
        }
        instructionsText={
          language === 'ES'
            ? originalText.volumeInstructions
            : VOLUME_CHECK_TRANSLATIONS.volumeInstructions
        }
        checkBar={language === 'ES' ? volumeCheckBar : volumeCheckBarES}
        yesButtonText={
          language === 'ES'
            ? originalText.yesButton
            : VOLUME_CHECK_TRANSLATIONS.yesButton
        }
        yesButtonAction={navigation}
        noButtonAction={handleOpen}
        noButtonText={
          language === 'ES'
            ? originalText.noButton
            : VOLUME_CHECK_TRANSLATIONS.noButton
        }
        step={page}
      >
        {
          url !== '' && (
            <VolumeCheckMedia
              mediaUrl={url}
              triggerStopSound={triggerStopSound}
            />
          )
        }
      </IntroductionContainer>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        page="volumeCheck"
      />
    </Container>
  );
}
