import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from '@mui/material';
import IntroductionContainer from '../IntroductionContainer/IntroductionContainer';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import { ASSESSMENT_RULES_TRANSLATIONS } from '../../utils/constants';
import assessmentRulesBar from '../../assets/assessmentRulesBar.png';
import assessmentRulesBarES from '../../assets/assessmentRulesBar_es.png';

export default function AssessmentRulesPage() {
  const [language, setLanguage] = useState('ES');

  const page = 'rulesPage';

  const originalText = {
    volumeTitle: 'Assessment Rules',
    volumeInstructions: [
      'This is a timed assessment.',
      "You can't pause the assessment.",
      ' If you exit the assessment, your progress will not be saved.',
      'You must be in a quiet place.',
      'You must do the assessment alone.',
      'You will receive your assessment results at the very end.'
    ],
    yesButton: 'I agree'
  };

  const navigate = useNavigate();

  function navigation() {
    navigate('/testConnect');
  }

  return (
    <Container
      className="instructionsContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 981,
        alignItems: 'center',
        height: '85vh'
      }}
    >
      <div style={{ alignSelf: 'flex-end', marginBottom: '20px' }}>
        <LanguageToggle language={language} setLanguage={setLanguage} />
      </div>
      <IntroductionContainer
        titleText={
          language === 'ES'
            ? originalText.volumeTitle
            : ASSESSMENT_RULES_TRANSLATIONS.volumeTitle
        }
        instructionsText={
          language === 'ES'
            ? originalText.volumeInstructions
            : ASSESSMENT_RULES_TRANSLATIONS.volumeInstructions
        }
        checkBar={language === 'ES' ? assessmentRulesBar : assessmentRulesBarES}
        yesButtonText={
          language === 'ES'
            ? originalText.yesButton
            : ASSESSMENT_RULES_TRANSLATIONS.yesButton
        }
        yesButtonAction={navigation}
        step={page}
      />
    </Container>
  );
}
