import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTestContext } from '../../../context/TestProvider';
import LargeButton from '../../LargeButton/LargeButton';
import './TestPartIntro.css';

export default function TestPartIntro() {
  const { testType, setProgressValue, setFetchNewSet } = useTestContext();
  const navigate = useNavigate();
  const handleAutoStart = () => {
    navigate('/test');
  };

  useEffect(() => {
    // resetting the counter and the progress bar on a new test
    sessionStorage.setItem('counter', 0);
    // removing old questions from session storage
    setFetchNewSet(true);
    setProgressValue(null);
  }, [testType]);

  return (
    <React.Fragment>
      <h1>{testType && testType}</h1>
      <LargeButton text="Start Assessment" onClick={handleAutoStart} />
    </React.Fragment>
  );
}
