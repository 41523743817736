import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import './PasswordResetLink.css';
import { useTheme } from '../../../context/ThemeProvider';

export default function PasswordResetLink() {
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const handleResendEmail = () => {};
  return (
    <Box component="div" className="passwordResetContainer">
      <Helmet>
        <title>BELA - Forgot Password</title>
      </Helmet>
      <Box component="div" className="boxContainer">
        <h1>Reset password link was sent successfully</h1>
        <Box component="div" className="successMessageContainer">
          <CheckCircleIcon color="success" />
          <p>
            A reset password link has been sent to your email at
            {'   '}
            {searchParams}
            {'   '}
            Please click the link to reset your password.
          </p>
        </Box>
        <p className="resendPasswordReset">
          Do not see your email?
          <button onClick={handleResendEmail} className="resendButton">
            Resend
          </button>
        </p>
      </Box>

      <p className="backSignInButton">
        Back to
        {'   '}
        <Link to="/">Sign In</Link>
      </p>
    </Box>
  );
}
