import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import ResetPasswordForm from '../../ResetPasswordFrom/ResetPasswordForm';
import './ForgotPasswordPage.css';
import { useTheme } from '../../../context/ThemeProvider';

export default function ForgotPasswordForm({ step, userEmail }) {
  const theme = useTheme();

  return (
    <Box component="div" className="forgotPasswordContainer">
      <Helmet>
        <title>BELA - Forgot Password</title>
      </Helmet>
      <ResetPasswordForm step={step} userEmail={userEmail} />
      <p className="backSignInButton">
        Back to
        {'   '}
        <Link to="/">Sign In</Link>
      </p>
    </Box>
  );
}
